import { Action, ActionBar, BottomSheet, BottomSheetFooter, Modal, Button, Avatar } from '@hh.ru/magritte-ui';
import { CrossOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';

import styles from './styles.less';

interface ImageViewModalProps {
    onModalClose: () => void;
    onUpload: () => void;
    onOpenAdvice: () => void;
    onRemoveLogo: () => void;
    visible: boolean;
    logoUrl?: string;
}

const TrlKeys = {
    upload: 'employer.logoEdit.modal.upload',
    recommendations: 'employer.logoEdit.modal.recommendations',
    remove: 'employer.logoEdit.modal.remove',
    title: 'employer.logoEdit.modal.view.title',
};

const ImageViewModal: TranslatedComponent<ImageViewModalProps> = ({
    trls,
    onModalClose,
    onUpload,
    onOpenAdvice,
    onRemoveLogo,
    visible,
    logoUrl,
}) => {
    const renderContent = () => {
        return (
            <div className={styles.container}>
                <Avatar
                    mode={logoUrl ? 'image' : 'placeholder'}
                    image={logoUrl}
                    fallbackMode="placeholder"
                    placeholder="city"
                    shapeCircle
                    size={208}
                    aria-label="Employer Logo"
                />
            </div>
        );
    };

    return (
        <>
            <Modal
                visible={visible}
                onClose={onModalClose}
                size="large"
                actions={<Action mode="secondary" onClick={onModalClose} icon={CrossOutlinedSize24} />}
                title={trls[TrlKeys.title]}
                footer={
                    <ActionBar
                        primaryActions={[
                            <Button
                                key={trls[TrlKeys.recommendations]}
                                size="medium"
                                style="accent"
                                mode="tertiary"
                                onClick={onOpenAdvice}
                            >
                                {trls[TrlKeys.recommendations]}
                            </Button>,
                            <Button
                                key={trls[TrlKeys.upload]}
                                size="medium"
                                style="accent"
                                mode="secondary"
                                onClick={onUpload}
                            >
                                {trls[TrlKeys.upload]}
                            </Button>,
                        ]}
                        secondaryActions={
                            <Button size="medium" style="negative" mode="tertiary" onClick={onRemoveLogo}>
                                {trls[TrlKeys.remove]}
                            </Button>
                        }
                    />
                }
            >
                {renderContent()}
            </Modal>
            <BottomSheet
                height="full-screen"
                visible={visible}
                onClose={onModalClose}
                footer={
                    <BottomSheetFooter>
                        <Button mode="secondary" style="accent" onClick={onUpload} size="large">
                            {trls[TrlKeys.upload]}
                        </Button>
                        <Button mode="tertiary" style="accent" onClick={onOpenAdvice} size="large">
                            {trls[TrlKeys.recommendations]}
                        </Button>
                        <Button style="negative" mode="tertiary" onClick={onRemoveLogo} size="large">
                            {trls[TrlKeys.remove]}
                        </Button>
                    </BottomSheetFooter>
                }
            >
                {renderContent()}
            </BottomSheet>
        </>
    );
};

export default translation(ImageViewModal);
