import { memo, Fragment, FC, ReactNode } from 'react';

import Slide from 'bloko/blocks/slide';
import Item from 'bloko/blocks/slide/Item';

import Switcher from 'src/components/EmployerConstructor/widgets/GalleryWidget/components/Switcher';

import styles from './slides-container.less';

export interface SlideData {
    id: string;
    content: ReactNode;
}

export type SetActiveSlide = (activeSlide: number) => void;

interface SlidesContainerProps {
    slides: SlideData[];
    activeSlide: number;
    setActiveSlide: SetActiveSlide;
}

const SlidesContainer: FC<SlidesContainerProps> = ({ slides, activeSlide, setActiveSlide }) => (
    <Fragment>
        <Slide activeItem={activeSlide - 1} onChange={(active) => setActiveSlide(active + 1)}>
            {slides.map(({ id, content: slideContent }) => (
                <Item key={id}>
                    <div className={styles.widgetGallerySlidesItem}>{slideContent}</div>
                </Item>
            ))}
        </Slide>
        {slides.length > 1 && (
            <div className={styles.widgetGallerySwitcherContainer}>
                <Switcher value={activeSlide} count={slides.length} onChange={setActiveSlide} />
            </div>
        )}
    </Fragment>
);

export default memo(SlidesContainer);
