import { useSelector } from 'src/hooks/useSelector';

interface UseResumesCount {
    countResumes: number;
    visibleResumesCount: number;
}

const useResumesCount = (): UseResumesCount => {
    const previousEmployerTargets = useSelector((state) => state.employerInfo.previousEmployerTargets);
    const resumesInfo = useSelector((state) => state.applicantInfo);
    const previousTargetIds = previousEmployerTargets?.map(({ id }) => id);
    const previousTargetIdsUnique = [...new Set(previousTargetIds)];
    const visibleResumesCount = resumesInfo.visible;
    let countResumes = previousTargetIdsUnique.length;
    if (countResumes > visibleResumesCount) {
        // если у пользователя есть скрытые резюме
        countResumes = visibleResumesCount;
    }

    return { countResumes, visibleResumesCount };
};

export default useResumesCount;
