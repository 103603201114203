import { useCallback, useRef, useMemo, ComponentPropsWithoutRef } from 'react';

import {
    IconDynamic,
    IconLink,
    IconColor,
    CrossScaleSmallEnclosedFalse,
    LupaScaleSmallKindDefaultAppearanceOutlined,
} from 'bloko/blocks/icon';
import InputText from 'bloko/blocks/inputText';
import { SuggestLayer } from 'bloko/blocks/suggest';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import {
    EMPTY_ITEM,
    PreparedAddress,
    AddressSuggest,
    createStaticAdressSuggestProvider,
    createRemoteAddressSuggestProvider,
} from 'Modules/EmployerAddresses/helpers';
import translation from 'src/components/translation';
import { EmployerAddress } from 'src/models/employerAddresses.types';

import AddressItem from 'src/components/AddressSuggest/AddressItem';

const TrlKeys = {
    metroStation: 'metrostation',
};

interface AddressBlokoSuggestProps {
    /** Адреса работодателя, как они лежат в стейте */
    addresses: EmployerAddress[];
    /** При установке в true, addresses будет игнорироваться, и саджест будет работать с удаленным поиском адресов */
    remoteSearch?: boolean;
    /** Выбранный адрес */
    address?: PreparedAddress;
    /** Значение которое должно быть в input-e если элемент используется как контролируемый */
    inputValue?: string;
    /** Колбэк вызывающийся при выборе элемента из саджеста, принимает выбранный адрес */
    onSelect: (selectedAddress: PreparedAddress) => void;
    /** bloko/suggest -> selectOnBlur */
    selectOnBlur?: boolean;
    /** bloko/suggest -> searchOnFocus */
    searchOnFocus?: boolean;
    /** placeholder input-а */
    placeholder?: string;
    /** Invalid свойство для input-a */
    invalid?: boolean;
    /** Колбэк для установки invalid свойства input-а */
    setInvalid?: (newInvalid: boolean) => void;
    /** Другие параметры, которые будут переданы в input */
    inputProps: { onChange: ComponentPropsWithoutRef<typeof InputText>['onChange']; disabled?: boolean };
    /** Максимальное количество отображаемых в дропдауне результатов */
    limit: number;
    /** Аналитика клика по инпуту */
    onClickAnalytics?: () => void;
    /** Положение списка по оси Z (устанавливает z-index) */
    layer?: SuggestLayer;
    /** id адреса, который не будет отображаться в дропдауне */
    addressIdNotToShow?: number;
    /** employerId, который нужен чтобы получить список адресов под бэкофисом */
    employerId?: string;
}

const AddressBlokoSuggest: TranslatedComponent<AddressBlokoSuggestProps> = ({
    addresses,
    remoteSearch,
    address,
    inputValue,
    trls,
    onSelect,
    selectOnBlur,
    searchOnFocus,
    placeholder,
    invalid,
    setInvalid,
    inputProps,
    limit,
    layer,
    addressIdNotToShow,
    onClickAnalytics,
    employerId = '',
}) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const handleInputFocus = () => setInvalid?.(false);
    const handleInputButtonIconClick = useCallback(() => {
        if (address !== EMPTY_ITEM) {
            onSelect(EMPTY_ITEM);
        }
        setTimeout(() => inputRef.current?.focus(), 0);
    }, [address, onSelect]);
    const dataProvider = useMemo(() => {
        if (remoteSearch) {
            return createRemoteAddressSuggestProvider(
                limit,
                employerId,
                trls[TrlKeys.metroStation],
                addressIdNotToShow
            );
        }
        return createStaticAdressSuggestProvider(addresses, trls[TrlKeys.metroStation], addressIdNotToShow);
    }, [addressIdNotToShow, addresses, employerId, limit, remoteSearch, trls]);

    const inputEnabled = !inputProps.disabled;

    return (
        <AddressSuggest
            dataProvider={dataProvider}
            limit={limit}
            layer={layer}
            suggestStartInputLength={0}
            itemContent={AddressItem}
            selectOnBlur={selectOnBlur}
            searchOnFocus={searchOnFocus}
            onChange={(item) => {
                item && onSelect(item);
            }}
            value={{
                ...address,
                text: inputValue || '',
            }}
        >
            <InputText
                onClick={onClickAnalytics}
                placeholder={placeholder}
                ref={inputRef}
                invalid={invalid}
                onFocus={handleInputFocus}
                icon={
                    inputEnabled && (
                        <IconDynamic>
                            <IconLink onClick={handleInputButtonIconClick}>
                                {address !== EMPTY_ITEM ? (
                                    <CrossScaleSmallEnclosedFalse
                                        initial={IconColor.Gray50}
                                        highlighted={IconColor.Gray60}
                                    />
                                ) : (
                                    <LupaScaleSmallKindDefaultAppearanceOutlined
                                        initial={IconColor.Gray50}
                                        highlighted={IconColor.Gray60}
                                    />
                                )}
                            </IconLink>
                        </IconDynamic>
                    )
                }
                data-qa="address-suggest-input"
                {...inputProps}
                autoComplete="off"
            />
        </AddressSuggest>
    );
};

export default translation(AddressBlokoSuggest);
