import { useState, useCallback, Fragment } from 'react';

import {
    Action,
    ActionBar,
    BottomSheet,
    BottomSheetFooter,
    Modal,
    Button,
    ScrollableContainer,
    NavigationBar,
} from '@hh.ru/magritte-ui';
import { ChevronLeftOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import ImageCrop, { CropResult, ImageCropProps } from 'src/components/ImageCropPopup/ImageCrop';
import { PointType } from 'src/components/ImageCropPopup/Points/pointType';
import translation from 'src/components/translation';
import useToggleState from 'src/hooks/useToggleState';

import styles from './styles.less';

interface ImageCropModalProps {
    onModalClose: () => void;
    onSave?: (cropImageParams: CropResult) => void;
    onDragStop?: () => void;
    onBack?: () => void;
    visible: boolean;
    resizeInProgress?: boolean;
    imageCropSettings: ImageCropProps;
    isLoading: boolean;
    shouldShowBack: boolean;
}

const TrlKeys = {
    save: 'employer.logoEdit.modal.crop.save',
    back: 'employer.logoEdit.modal.crop.back',
    close: 'employer.logoEdit.modal.crop.close',
};

const ImageCropModal: TranslatedComponent<ImageCropModalProps> = ({
    trls,
    onModalClose,
    onDragStop,
    onSave,
    visible,
    onBack,
    imageCropSettings,
    isLoading,
    shouldShowBack,
}) => {
    const [cropImageParams, setCropImageParams] = useState<CropResult | null>(null);
    const [dragged, , setDragged] = useToggleState(false);

    /** с таймаутом чтобы в момент, когда зона выделения тянется дальше зоны модалки, попап не закрывался */
    const onDragStopCallback = useCallback(() => {
        onDragStop?.();
        setTimeout(() => {
            setDragged(false);
        });
    }, [onDragStop, setDragged]);

    const onDragStart = useCallback(() => {
        setDragged(true);
    }, [setDragged]);

    const onSaveWrapper = useCallback(() => {
        if (cropImageParams) {
            onSave?.(cropImageParams);
        }
    }, [cropImageParams, onSave]);

    const closePopup = useCallback(() => {
        if (!dragged) {
            onModalClose?.();
        }
    }, [dragged, onModalClose]);

    const renderCrop = () => {
        return (
            <ImageCrop
                {...imageCropSettings}
                onResizeCallback={setCropImageParams}
                onDragStart={onDragStart}
                onDragStop={onDragStopCallback}
                pointType={PointType.Corner}
            />
        );
    };

    return (
        <>
            <Modal
                visible={visible}
                onClose={closePopup}
                size="large"
                footer={
                    <ActionBar
                        primaryActions={[
                            <Fragment key={trls[TrlKeys.back]}>
                                {shouldShowBack && (
                                    <Button
                                        size="medium"
                                        style="accent"
                                        mode="tertiary"
                                        onClick={onBack}
                                        data-qa="logo-crop-modal-back"
                                    >
                                        {trls[TrlKeys.back]}
                                    </Button>
                                )}
                            </Fragment>,
                            <Button
                                key={trls[TrlKeys.save]}
                                size="medium"
                                style="accent"
                                mode="primary"
                                onClick={onSaveWrapper}
                                loading={isLoading}
                                data-qa="logo-crop-modal-save"
                            >
                                {trls[TrlKeys.save]}
                            </Button>,
                        ]}
                        secondaryActions={
                            <Button
                                size="medium"
                                style="accent"
                                mode="tertiary"
                                onClick={onModalClose}
                                data-qa="logo-crop-modal-close"
                            >
                                {trls[TrlKeys.close]}
                            </Button>
                        }
                    />
                }
            >
                <div className={styles.modalContainer}>{renderCrop()}</div>
            </Modal>
            <BottomSheet
                header={
                    !!onBack && <NavigationBar left={<Action icon={ChevronLeftOutlinedSize24} onClick={onBack} />} />
                }
                height="full-screen"
                visible={visible}
                onClose={onModalClose}
                footer={
                    <BottomSheetFooter>
                        <Button
                            key="Сохранить"
                            size="large"
                            style="accent"
                            mode="primary"
                            onClick={onSaveWrapper}
                            loading={isLoading}
                        >
                            {trls[TrlKeys.save]}
                        </Button>
                    </BottomSheetFooter>
                }
            >
                <div className={styles.container}>
                    <ScrollableContainer>{renderCrop()}</ScrollableContainer>
                </div>
            </BottomSheet>
        </>
    );
};

export default translation(ImageCropModal);
