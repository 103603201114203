export enum DraggedPointType {
    LeftTop = 'left-top',
    RightTop = 'right-top',
    RightBottom = 'right-bottom',
    LeftBottom = 'left-bottom',
}

export enum PointType {
    Circle = 'circle',
    Corner = 'corner',
}
