import { useEffect, useRef, useState, memo, FC } from 'react';

import { YMapsInstance } from 'Modules/Maps/maps.types';
import { MapData } from 'src/components/AddressSuggest/types';
import { useSelector } from 'src/hooks/useSelector';
import { EmployerAddress } from 'src/models/employerAddresses.types';
import { VacancyAddress } from 'src/models/vacancyView.types';

import styles from './address-widget-map.less';

const createMap = async (element: HTMLDivElement, address: VacancyAddress, isWebView: boolean) => {
    let mapInstance = null;
    if (!process.env.SSR) {
        const showMapModule = await import(/* webpackMode: "eager" */ 'Modules/Maps/showMap');
        const result = await showMapModule.default(element, {
            address,
            disableScrollZoom: true,
            disableDrag: isWebView,
            disableDblClickZoom: isWebView,
            zoomControlPosition: {
                left: 10,
                top: 30,
            },
        });
        mapInstance = result?.mapInstance || null;
    }
    return mapInstance;
};

interface AddressWidgetMapProps {
    address: EmployerAddress;
}

const AddressWidgetMap: FC<AddressWidgetMapProps> = ({ address }) => {
    const mapContainer = useRef<HTMLDivElement>(null);
    const mapInstance = useRef<YMapsInstance | null>();
    const webViewMode = useSelector(({ employerConstructorWebViewMode }) => employerConstructorWebViewMode);

    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        let mapData: MapData | null = null;
        try {
            if (typeof address.mapData === 'string') {
                mapData = JSON.parse(address.mapData) as MapData;
            }
        } catch (_) {} // eslint-disable-line no-empty
        const mapDataAvailable = !!mapData?.points;
        if (mapDataAvailable && mapContainer.current && !!mapData?.points) {
            createMap(mapContainer.current, { ...address, mapData }, webViewMode)
                .then((yandexMapInstance) => {
                    mapInstance.current = yandexMapInstance;
                    return null;
                })
                .catch(() => {
                    setHasError(true);
                });
        } else if (!mapDataAvailable) {
            setHasError(true);
        }
        return () => {
            mapInstance.current?.destroy?.();
        };
    }, [address, webViewMode]);

    if (hasError) {
        return null;
    }

    return <div className={styles.widgetAddressMap} ref={mapContainer} />;
};

const ManualCheckChangeAddress = (prevProps: AddressWidgetMapProps, nextProps: AddressWidgetMapProps) =>
    prevProps.address.id === nextProps.address.id;

export default memo(AddressWidgetMap, ManualCheckChangeAddress);
