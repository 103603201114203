import { DefaultRootState } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';

import defaultError from 'src/components/Notifications/DefaultError';
import { employerConstructorEditForbiddenError } from 'src/components/Notifications/EmployerConstructor';
import { AddNotification } from 'src/components/Notifications/Provider/types';
import {
    employerConstructorAddImage,
    Status,
    switchImagesActionWrapper,
    AreaSelection,
    EmployerConstructorImage,
} from 'src/models/employerConstructor';
import fetcher from 'src/utils/fetcher';

import checkResizeStatus, { CHECK_TIMEOUT_MS } from 'src/components/EmployerConstructor/checkResizeStatus';
import getErrorType, { EmployerConstructorError } from 'src/components/EmployerConstructor/getErrorType';

const RESIZE_IMAGE_URL = '/employer/constructor/resize_image';

declare global {
    interface FetcherPostApi {
        [RESIZE_IMAGE_URL]: {
            queryParams: void;
            body: {
                pictureId: number;
                selectionLeft: number;
                selectionTop: number;
                selectionWidth: number;
                selectionHeight: number;
            };
            response: EmployerConstructorImage;
        };
    }
}

export interface ResizeParams extends AreaSelection {
    originalWidth?: number;
    originalHeight?: number;
}
type ResizeImage = (
    params: { pictureId: number; resizeParams: ResizeParams; widgetId?: number },
    showErrorAction: (errorType: string) => AnyAction,
    addNotification: AddNotification
) => ThunkAction<Promise<EmployerConstructorImage>, DefaultRootState, unknown, AnyAction>;

const resizeImage: ResizeImage =
    ({ pictureId, resizeParams, widgetId }, showErrorAction, addNotification) =>
    (dispatch) => {
        return new Promise((resolve, reject) => {
            dispatch(
                switchImagesActionWrapper({
                    payload: {
                        resizeStatus: Status.Fetching,
                    },
                    widgetId,
                })
            );
            const { x, y, width, height, ...rest } = resizeParams;

            return fetcher
                .postFormData(RESIZE_IMAGE_URL, {
                    pictureId,
                    selectionLeft: x,
                    selectionTop: y,
                    selectionWidth: width,
                    selectionHeight: height,
                    ...rest,
                })
                .then(
                    ({ data }) => {
                        dispatch([
                            employerConstructorAddImage(data),
                            switchImagesActionWrapper({
                                payload: {
                                    resizeStatus: Status.Fetching,
                                },
                                widgetId,
                            }),
                        ] as unknown as AnyAction);
                        setTimeout(() => {
                            dispatch(
                                checkResizeStatus(
                                    {
                                        pictureId: data.pictureId,
                                        widgetId,
                                    },
                                    {
                                        showErrorAction,
                                    },
                                    addNotification
                                )
                            )
                                .then(() => resolve(data))
                                .catch(() => reject(new Error('Failed to check resize status')));
                        }, CHECK_TIMEOUT_MS);
                    },
                    (error) => {
                        dispatch(switchImagesActionWrapper({ payload: { resizeStatus: Status.Fail }, widgetId }));
                        const errorType = getErrorType(error);
                        switch (errorType) {
                            case EmployerConstructorError.EditNotPermitted:
                                addNotification(employerConstructorEditForbiddenError);
                                break;
                            case EmployerConstructorError.InvalidSelectionLocation:
                            case EmployerConstructorError.InvalidSelectionSize:
                            case EmployerConstructorError.InvalidSelectionRatio:
                                dispatch(showErrorAction(errorType));
                                break;
                            default:
                                addNotification(defaultError);
                        }
                        reject(new Error('Failed to resize image'));
                    }
                );
        });
    };

export default resizeImage;
