import { translation } from '@hh.ru/front-static-app';
import { Placeholder } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

const TrlKeys = {
    title: 'employerView.content.emptyDescription.title',
    description: 'employerView.content.emptyDescription.description',
};

const EmptyDescription: TranslatedComponent = ({ trls }) => {
    return (
        <Placeholder
            title={trls[TrlKeys.title]}
            description={trls[TrlKeys.description]}
            paddingTop={40}
            paddingBottom={40}
        />
    );
};

export default translation(EmptyDescription);
