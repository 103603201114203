import { FC, useMemo, useRef, useState } from 'react';

import { ActionList, Button, ButtonStack } from '@hh.ru/magritte-ui';
import { EllipsisHorizontalOutlinedSize16 } from '@hh.ru/magritte-ui/icon';

import ContainerForMicroFrontend from 'src/components/CodeInjector/ContainerForMicroFrontend';
import useResumesCount from 'src/components/Employer/EmployerView/Header/hooks/useResumesCount';
import WantWorkHere from 'src/components/Employer/Sidebar/WantWorkHere';
import { useSelector } from 'src/hooks/useSelector';

import Complain from 'src/components/Employer/EmployerView/Header/Actions/Complain';
import Subscribe from 'src/components/Employer/EmployerView/Header/Actions/Subscribe';
import WantWorkHereAlready from 'src/components/Employer/EmployerView/Header/Actions/WantWorkHereAlready';

const MobileActions: FC = () => {
    const employerButtons = useSelector((state) => state.employerButtons);
    const [visible, setVisible] = useState(false);
    const activatorRef = useRef(null);

    const { countResumes, visibleResumesCount } = useResumesCount();

    const mobileButtons = useMemo(() => {
        const result = [];

        if (employerButtons.saveSearch) {
            result.push(
                <Subscribe key="subscribe" stretched isMenuItem size="medium" onClick={() => setVisible(false)} />
            );
        }
        if (visibleResumesCount !== countResumes || visibleResumesCount === 0) {
            result.push(<WantWorkHere key="want_work_here" stretched isMenuItem />);
        }
        if (countResumes > 0) {
            result.push(<WantWorkHereAlready key="want_work_here_already" isMenuItem />);
        }
        if (!employerButtons.complain?.success) {
            result.push(<Complain onClick={() => setVisible(false)} />);
        }

        return result;
    }, [countResumes, employerButtons.complain, employerButtons.saveSearch, visibleResumesCount]);

    return (
        <>
            <ContainerForMicroFrontend key="employer_complain_menu_item" place="employerComplainMenuItem" />
            <Button
                mode="secondary"
                size="small"
                ref={activatorRef}
                onClick={() => setVisible(true)}
                icon={<EllipsisHorizontalOutlinedSize16 />}
            />
            <ActionList
                visible={visible}
                onClose={() => setVisible(false)}
                dropProps={{ placement: 'bottom-right', activatorRef }}
            >
                <ButtonStack orientation="vertical">{mobileButtons}</ButtonStack>
            </ActionList>
        </>
    );
};

export default MobileActions;
