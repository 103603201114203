import { useCallback, useEffect, useMemo, useState } from 'react';

import {
    CheckableChip,
    CustomChip,
    ChipsContainer,
    FormLabel,
    Link,
    VSpacing,
    Input,
    useBreakpoint,
} from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import SuggestWithDefaultErrorPlaceholder from 'src/components/SuggestWithDefaultErrorPlaceholder';
import translation from 'src/components/translation';
import fetcher from 'src/utils/fetcher';
import { useSelectOnBlur } from 'src/utils/suggest/useSelectOnBlur';

import MetroPin from 'src/components/AddressSuggest/AddressMetroPin';
import { Coordinate, MetroStation, Metro, Cities } from 'src/components/AddressSuggest/types';
import useMetroDataProvider from 'src/components/AddressSuggest/useMetroDataProvider';
import { prepareCoordinates, prepareMetroItem, cutMetro } from 'src/components/AddressSuggest/utils';

const GET_METROS_URL = '/shards/metro/closest';
const GET_CITIES_URL = '/shards/metro/cities';

declare global {
    interface FetcherGetApi {
        [GET_METROS_URL]: {
            queryParams: {
                lang: string;
                site: string;
                lat: number;
                lng: number;
            };
            response: { metroStation?: MetroStation[] };
        };
        [GET_CITIES_URL]: {
            queryParams: void;
            response: Cities;
        };
    }
}

const TrlKeys = {
    selectMetro: 'employer.address.select.metro',
    editMetro: 'employer.address.edit.metro',
    editMetroOther: 'employer.address.edit.metro.other',
};

interface MetrosProps {
    coordinates: number[];
    city: string;
    selectedMetro: MetroStation[];
    onMapUpdate: (coordinates: Coordinate[]) => void;
    onMetroSelect: (metros: Metro[]) => void;
}

const CUT_LENGTH = 15;

const Metros: TranslatedComponent<MetrosProps> = ({
    trls,
    coordinates,
    city,
    selectedMetro,
    onMapUpdate,
    onMetroSelect,
}) => {
    const [lat, lng] = coordinates;
    const [inputValue, setInputValue] = useState('');
    const [metros, setMetros] = useState<Metro[]>([]);
    const [removableMetros, setRemovableMetros] = useState<Metro[]>([]);
    const [cities, setCities] = useState<Cities>([]);
    const [selected, setSelected] = useState<number[]>([]);
    const [showSearchMetro, setShowSearchMetro] = useState(false);

    const { isMobile } = useBreakpoint();

    const cityId = useMemo(() => {
        const value = Object.keys(cities).find((id) => {
            const currentCity = cities[id];

            if (Array.isArray(currentCity)) {
                return currentCity.includes(city);
            }
            return false;
        });
        // Если по городу не нашлось, ищем ID по первой станции метро
        if (!value && metros[0]?.cityId) {
            return String(metros[0].cityId);
        }
        return String(value);
    }, [cities, city, metros]);

    const handleSelect = (value: string, data: Metro | undefined) => {
        if (data) {
            const metro = {
                id: data.id,
                name: data.name,
                text: data.name ?? '',
                line: data.line,
                lat: data.lat,
                lng: data.lng,
            };
            setInputValue('');

            const newSelected = [...selected, data.id];
            setSelected(newSelected);

            let selectedMetros = [...metros];

            if (!metros.some(({ id }) => id === data.id)) {
                setRemovableMetros([metro]);
                setShowSearchMetro(false);
                selectedMetros = [...metros, metro].filter((metro) => newSelected.includes(metro.id));
            } else {
                selectedMetros = metros.filter((metro) => newSelected.includes(metro.id));
            }

            onMetroSelect(selectedMetros);
            onMapUpdate(selectedMetros.map(prepareCoordinates));
        }
        return false;
    };

    const handleSelectStation = useCallback(
        (metroId: number) => {
            let newSelected: number[];
            if (selected.includes(metroId)) {
                newSelected = selected.filter((id) => metroId !== id);
            } else {
                newSelected = [...selected, metroId];
            }
            setSelected(newSelected);
            const selectedMetros = [...metros, ...removableMetros].filter((metro) => newSelected.includes(metro.id));

            onMetroSelect(selectedMetros);
            onMapUpdate(selectedMetros.map(prepareCoordinates));
        },
        [metros, onMapUpdate, onMetroSelect, removableMetros, selected]
    );

    const handleDeleteStation = (metroId: number | undefined) => {
        const newRemovableMetros = removableMetros.filter(({ id }) => metroId !== id);
        setRemovableMetros(newRemovableMetros);

        const newSelected = selected.filter((id) => metroId !== id);
        setSelected(newSelected);

        const selectedMetros = [...metros, ...newRemovableMetros].filter(() => newSelected.includes(metroId));

        onMetroSelect(selectedMetros);
        onMapUpdate(selectedMetros.map(prepareCoordinates));
    };

    const [select, pickFirstValue, onSaveFirst] = useSelectOnBlur(inputValue, handleSelect);

    const dataProvider = useMetroDataProvider(cityId, onSaveFirst);

    useEffect(() => {
        async function getMetros() {
            const metroPromise = fetcher.get(GET_METROS_URL, {
                params: {
                    lang: window.globalVars.lang,
                    site: window.globalVars.siteId,
                    lat,
                    lng,
                },
            });
            const citiesPromise = fetcher.get(GET_CITIES_URL);
            const [metroResponse, citiesResponse] = await Promise.all([metroPromise, citiesPromise]);

            const { metroStation } = metroResponse;
            if (!metroStation) {
                return;
            }

            const metros = metroStation.map(prepareMetroItem);
            const removableMetros = selectedMetro.filter((metro) => !metros.some((m) => m.id === metro.id));
            setMetros(metros);
            setRemovableMetros(removableMetros.map(prepareMetroItem));
            setSelected(selectedMetro.map(({ id }) => id));
            setCities(citiesResponse);
        }

        void getMetros();
    }, [lat, lng, selectedMetro]);

    if (!metros.length) {
        return null;
    }

    return (
        <>
            <VSpacing default={24} />
            <FormLabel>{trls[TrlKeys.editMetro]}</FormLabel>
            <div data-qa="add-address-modal-metro-list">
                <VSpacing default={12} />
                <ChipsContainer correction={12}>
                    {metros.map((metro) => (
                        <CheckableChip
                            key={metro.id}
                            type="checkbox"
                            checked={selected.includes(metro.id)}
                            onChange={() => handleSelectStation(metro.id)}
                            addon={<MetroPin color={metro.line?.color} />}
                        >
                            <span data-metro-id={metro.id}>{cutMetro(metro.name, CUT_LENGTH, isMobile)}</span>
                        </CheckableChip>
                    ))}
                </ChipsContainer>
                {removableMetros.length === 0 && (
                    <>
                        <VSpacing default={12} />
                        {showSearchMetro ? (
                            <SuggestWithDefaultErrorPlaceholder
                                inputValue={inputValue}
                                onSelectValidator={select}
                                onBlur={pickFirstValue}
                                input={{
                                    component: Input,
                                    props: {
                                        onChange: setInputValue,
                                        'data-qa': 'address-edit-metro-suggest',
                                        placeholder: trls[TrlKeys.selectMetro],
                                        size: 'medium',
                                    },
                                }}
                                dataProvider={dataProvider}
                            />
                        ) : (
                            <Link onClick={() => setShowSearchMetro(true)} data-qa="address-edit-metro-other">
                                {trls[TrlKeys.editMetroOther]}
                            </Link>
                        )}
                    </>
                )}
                {removableMetros.length > 0 && (
                    <>
                        <VSpacing default={12} />
                        <ChipsContainer correction={12}>
                            {removableMetros.map((metro) => (
                                <CustomChip
                                    key={metro.id}
                                    checked={selected.includes(metro.id)}
                                    onClick={() => handleSelectStation(metro.id)}
                                    onDelete={() => handleDeleteStation(metro.id)}
                                    addon={<MetroPin color={metro.line?.color} />}
                                >
                                    <span data-metro-id={metro.id}>{cutMetro(metro.name, CUT_LENGTH, isMobile)}</span>
                                </CustomChip>
                            ))}
                        </ChipsContainer>
                    </>
                )}
            </div>
        </>
    );
};

export default translation(Metros);
