import { memo, useRef } from 'react';

import { TooltipHover } from '@hh.ru/magritte-ui';
import {
    DreamjobSize16,
    ItAccreditationSize16,
    HrBrandOutlinedSize16,
    EmployersRatingOutlinedSize16,
    CompanysLifeOutlinedSize16,
} from '@hh.ru/magritte-ui/product';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import scrollToElement from 'Utils/ScrollToElement';
import translation from 'src/components/translation';
import useGetCompanyHHRatingBadge from 'src/hooks/companies/useGetCompanyHHRatingBadge';
import { useSelector } from 'src/hooks/useSelector';

import Badge from 'src/components/Employer/EmployerView/Header/MiniAdvantages/Badge';
import Separator from 'src/components/Employer/EmployerView/Header/MiniAdvantages/Separator';

import styles from './mini-advantages.less';

const TrlKeys = {
    goToAdvantages: 'employerView.header.goToAdvantages',
};

// it-accreditation
// dreamjob
// hr-brand
// employers-rating
// интервью
// TODO: спецпроекты при раскатке экспа

const MiniAdvantages: TranslatedComponent = ({ trls }) => {
    const accreditedITEmployer = useSelector((state) => state.employerInfo.accreditedITEmployer);
    const isOpenEmployer = useSelector((state) => state.employerReviewsIsOpenEmployer);
    const hrBrand = useSelector((state) => state.employerInfo.hrBrand);
    const badges = useSelector((state) => state.employerInfo.badges);
    const insider = useSelector((state) => state.employerInsiderInterview);
    const hasInsiderArticles = !!insider?.articles?.length;

    const employerHHRatingBadge = useGetCompanyHHRatingBadge(badges);

    const activatorRef = useRef(null);

    if (!(accreditedITEmployer || isOpenEmployer || hrBrand || employerHHRatingBadge || hasInsiderArticles)) {
        return null;
    }

    return (
        <>
            <TooltipHover placement="bottom-center" size="small" activatorRef={activatorRef}>
                {trls[TrlKeys.goToAdvantages]}
            </TooltipHover>
            <Separator />
            <div
                className={styles.root}
                onClick={() => {
                    const scrollTarget = document.querySelector('[data-scroll-target="employer-advantages"]');
                    scrollToElement(scrollTarget);
                }}
                ref={activatorRef}
            >
                {accreditedITEmployer && (
                    <Badge>
                        <ItAccreditationSize16 />
                    </Badge>
                )}
                {isOpenEmployer && (
                    <Badge>
                        <DreamjobSize16 />
                    </Badge>
                )}
                {hrBrand && (
                    <Badge>
                        <HrBrandOutlinedSize16 />
                    </Badge>
                )}
                {employerHHRatingBadge && (
                    <Badge>
                        <EmployersRatingOutlinedSize16 />
                    </Badge>
                )}
                {hasInsiderArticles && (
                    <Badge>
                        <CompanysLifeOutlinedSize16 />
                    </Badge>
                )}
            </div>
        </>
    );
};

export default memo(translation(MiniAdvantages));
