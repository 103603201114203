import { useRef } from 'react';
import { GetInputPropsFn } from 'react-dropzone';

import conctructorAddHeaderPictureButtonClick from '@hh.ru/analytics-js-events/build/xhh/employer/branding/employer_page/conctructor_add_header_picture_button_click';
import { CameraOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import HoverTip from 'bloko/blocks/drop/Tip/HoverTip';
import Loading, { LoadingScale, LoadingColor } from 'bloko/blocks/loading';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import BlokoIconReplaceContainer from 'src/components/BlokoIconReplaceContainer';
import { MixedEvent } from 'src/components/EmployerConstructor/getFileFromEvent';
import translation from 'src/components/translation';
import { Status } from 'src/models/employerConstructor';

import styles from './employer-constructor-header.less';

interface HeaderEditPromoUploadButtonProps {
    allowedMimeTypes?: string;
    resizeStatus?: string;
    onLoad?: (event: MixedEvent) => void;
    getInputProps: GetInputPropsFn;
}

const TrlKeys = {
    uploadTooltip: 'employer.constructor.header.promo.upload.tooltip',
};

const HeaderEditPromoUploadButton: TranslatedComponent<HeaderEditPromoUploadButtonProps> = ({
    trls,
    getInputProps,
    resizeStatus,
    allowedMimeTypes,
    onLoad,
}) => {
    const activatorRef = useRef(null);
    return (
        <HoverTip render={() => trls[TrlKeys.uploadTooltip]} activatorRef={activatorRef}>
            <label
                className={styles.employerConstructorHeaderPromoUploadButton}
                onClick={() => conctructorAddHeaderPictureButtonClick()}
                ref={activatorRef}
            >
                {resizeStatus === Status.Fetching ? (
                    <Loading initial={LoadingColor.Gray80} scale={LoadingScale.Medium} />
                ) : (
                    <span className={styles.employerConstructorHeaderPromoUploadIcon}>
                        <BlokoIconReplaceContainer>
                            <CameraOutlinedSize24 initial="contrast" />
                        </BlokoIconReplaceContainer>
                    </span>
                )}
                <input
                    {...getInputProps()}
                    type="file"
                    className="g-hidden"
                    accept={allowedMimeTypes}
                    onChange={onLoad}
                    data-qa="constructor-head__promo-upload-image-input"
                />
            </label>
        </HoverTip>
    );
};

export default translation(HeaderEditPromoUploadButton);
