import { FC } from 'react';

import { ChevronLeftOutlinedSize16, ChevronRightOutlinedSize16 } from '@hh.ru/magritte-ui/icon';

import BlokoIconReplaceContainer from 'src/components/BlokoIconReplaceContainer';

import styles from './switcher.less';

interface SwitcherProps {
    count: number;
    value: number;
    onChange: (newCount: number) => void;
}

const Switcher: FC<SwitcherProps> = ({ count, value, onChange }) => (
    <div className={styles.widgetGallerySwitcher}>
        <button
            data-qa="widget-gallery-switcher-button-left"
            className={styles.widgetGallerySwitcherButton}
            onClick={() => onChange(value <= 1 ? count : value - 1)}
        >
            <BlokoIconReplaceContainer>
                <ChevronLeftOutlinedSize16 />
            </BlokoIconReplaceContainer>
        </button>
        <span>
            {value} / {count}
        </span>
        <button
            data-qa="widget-gallery-switcher-button-right"
            className={styles.widgetGallerySwitcherButton}
            onClick={() => onChange(value >= count ? 1 : value + 1)}
        >
            <BlokoIconReplaceContainer>
                <ChevronRightOutlinedSize16 />
            </BlokoIconReplaceContainer>
        </button>
    </div>
);

export default Switcher;
