import { FC, PropsWithChildren, useEffect, useRef } from 'react';
import classnames from 'classnames';

import { useBreakpoint } from '@hh.ru/magritte-ui';

import styles from './add-address-modal.less';

interface AddressModalContentWrapperProps extends PropsWithChildren {
    visible: boolean;
    setModalContentMaxHeight: (maxHeight: number) => void;
}

const findNearestScrollableContainer = (htmlElement?: HTMLElement | null) => {
    if (htmlElement instanceof HTMLElement) {
        const computedStyles = getComputedStyle(htmlElement);
        if (computedStyles.overflowY === 'auto' || computedStyles.overflowY === 'scroll') {
            return htmlElement;
        }
        return findNearestScrollableContainer(htmlElement.parentElement);
    }
    return null;
};

const AddressModalContentWrapper: FC<AddressModalContentWrapperProps> = ({
    children,
    visible,
    setModalContentMaxHeight,
}) => {
    const modalContentRef = useRef<HTMLDivElement>(null);
    const { isGtS } = useBreakpoint();

    useEffect(() => {
        // Согласно дизайну в модальном окне не должно быть скрола, но должен быть скрол в сайдбаре, если контент не вмещается
        // Для справки: https://jira.hh.ru/browse/PORTFOLIO-28793
        // Чтобы возник скрол в сайдбаре нужна фиксированная высота контейнера
        // Сейчас модалка по высоте резиновая и зависит от размера содержимого, для того чтобы этого добиться было выработано краткосрочное и долгосрочное решение
        // В качестве краткосрочного решения handleModalContentResize задает фиксированную высоту контенту модалки и пересчитывает её когда меняется размер экрана
        // В качестве долгосрочного решения завели задачу на доработку компонента Modal
        // После которой появится возможность прокинуть проп height: "full-size", тогда модалка должна будет отрисоваться сразу на всю возможную высоту
        // TODO [https://jira.hh.ru/browse/HH-215047]: выпилить handleModalContentResize когда будет готова доработка компонента magritte-ui Modal
        const handleModalContentResize = () => {
            const documentRoot = document.querySelector<HTMLElement>(':root')!;
            const modalContentContainer = findNearestScrollableContainer(modalContentRef.current?.parentElement);
            if (visible && modalContentContainer && documentRoot) {
                const element = document.createElement('div');
                element.style.height = `${window.innerHeight}px`;
                const overflow = modalContentContainer.style.overflow;
                modalContentContainer.style.overflow = 'hidden';
                modalContentContainer.appendChild(element);
                const modalContentMaxHeight = modalContentContainer.clientHeight;
                setModalContentMaxHeight(modalContentMaxHeight);
                documentRoot.style.setProperty('--modal-content-height', `${modalContentMaxHeight}px`);
                modalContentContainer.removeChild(element);
                modalContentContainer.style.overflow = overflow;
            }
        };

        handleModalContentResize();
        window.addEventListener('resize', handleModalContentResize);

        return () => {
            window.removeEventListener('resize', handleModalContentResize);
        };
    }, [visible, isGtS, setModalContentMaxHeight]);

    return (
        <div
            ref={modalContentRef}
            className={classnames(styles.addressModalContent, {
                [styles.addressModalContentFullScreen]: isGtS,
            })}
        >
            {children}
        </div>
    );
};

export default AddressModalContentWrapper;
