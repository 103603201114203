import { useState, useRef, useEffect } from 'react';

import { useReplace } from '@hh.ru/redux-spa-middleware';
import urlParser from 'bloko/common/urlParser';

import useNote from 'src/hooks/useNote';
import { useSelector } from 'src/hooks/useSelector';

export enum ModalType {
    ImageView = 'imageView',
    Advice = 'advice',
    ImageCropModal = 'imageCropModal',
    ImageDelete = 'ImageDelete',
}

export enum AlertType {
    Exit = 'exit',
    Remove = 'remove',
    Back = 'back',
}

const Notes = {
    employerCircleLogoInfoHasShown: 'employer_circle_logo_info_has_shown',
};

interface ModalManagementType {
    visibleModalType: ModalType | null;
    handleOpenDialog: () => void;
    handleSetVisibleModalType: (type: ModalType | null) => void;
    handleCloseModal: () => void;
    handleBack: () => void;
    shouldShowBack: boolean;
    handleCloseAlert: () => void;
    shouldShowAlertType: boolean;
    handleShowAlert: (type: AlertType) => void;
    alertModalType: AlertType | null;
    shouldShowCommunicationModal: boolean;
    handleCloseCommunicationModal: () => void;
}

const useModalManagement = (
    hasLogo: boolean,
    hasNewLogo: boolean,
    setImage: (src: string) => void
): ModalManagementType => {
    const [employerCircleLogoInfoHasShown, setNoteValue] = useNote<boolean>(Notes.employerCircleLogoInfoHasShown);

    const prevImageCropModal = useRef<ModalType[]>([]);
    const [visibleModalType, setVisibleModalType] = useState<ModalType | null>(null);
    const [showAlertType, setShowAlertType] = useState<AlertType | null>(null);
    const [shouldShowCommunicationModal, setShouldShowCommunicationModal] = useState(false);
    const isFromUserNotification = useSelector((state) => state.router.location.query.fromRoundLogoNotification);
    const replace = useReplace();

    const handleOpenDialog = () => {
        if (!hasLogo) {
            setVisibleModalType(ModalType.Advice);
        }

        if (hasLogo && !hasNewLogo) {
            setVisibleModalType(ModalType.Advice);
        }

        if (hasLogo && hasNewLogo) {
            setVisibleModalType(ModalType.ImageView);
        }
    };

    const handleSetVisibleModalType = (type: ModalType | null) => {
        if (visibleModalType) {
            prevImageCropModal.current.push(visibleModalType);
        }
        setVisibleModalType(type);
    };

    const handleBack = () => {
        const backType = prevImageCropModal.current.pop() || null;
        setImage('');
        setShowAlertType(null);
        setVisibleModalType(backType);
    };

    const handleCloseModal = () => {
        setImage('');
        prevImageCropModal.current = [];
        setShowAlertType(null);
        setVisibleModalType(null);
    };

    const handleCloseCommunicationModal = () => {
        setShouldShowCommunicationModal(false);
    };

    const handleShowCommunicationModal = () => {
        setShouldShowCommunicationModal(true);
        void setNoteValue(true);
    };

    useEffect(() => {
        if (!hasNewLogo && isFromUserNotification) {
            const url = urlParser(`${location.pathname}${location.search}`);
            delete url.params.fromRoundLogoNotification;
            replace(url.href, undefined, { cancelFetchingData: true });
            handleOpenDialog();
        }

        if (hasLogo && !hasNewLogo && !employerCircleLogoInfoHasShown) {
            handleShowCommunicationModal();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        visibleModalType,
        handleOpenDialog,
        handleSetVisibleModalType,
        handleCloseModal,
        handleBack,
        shouldShowBack: !!prevImageCropModal.current.length,
        handleCloseAlert: () => setShowAlertType(null),
        shouldShowAlertType: !!showAlertType,
        handleShowAlert: setShowAlertType,
        alertModalType: showAlertType,
        shouldShowCommunicationModal,
        handleCloseCommunicationModal,
    };
};

export default useModalManagement;
