import { useCallback, useMemo, useState } from 'react';

interface CropPictureProps {
    pictureId: number | null;
    typedPictureId: number | null;
    visible: boolean;
    onClose: () => void;
}

export type SetCropPictureId = (pictureId: number, typedPictureId?: number | null) => void;

interface UseImageCropResult {
    cropPictureProps: CropPictureProps;
    setCropPictureId: SetCropPictureId;
}

const useImageCrop = (): UseImageCropResult => {
    const [visible, setVisible] = useState(false);
    const [typedPictureId, setTypesPictureId] = useState<number | null>(null);
    const [pictureId, setPictureId] = useState<number | null>(null);

    const cropPictureProps: CropPictureProps = useMemo(
        () => ({
            pictureId,
            typedPictureId,
            visible,
            onClose: () => {
                setVisible(false);
                setPictureId(null);
                setTypesPictureId(null);
            },
        }),
        [pictureId, typedPictureId, visible]
    );

    const setCropPictureId: SetCropPictureId = useCallback((pictureId, typedPictureId = null) => {
        setPictureId(pictureId);
        setTypesPictureId(typedPictureId);
        setVisible(true);
    }, []);

    return { cropPictureProps, setCropPictureId };
};

export default useImageCrop;
