import { FC, PropsWithChildren, useRef } from 'react';

import { AbortApplyFiltersContext } from 'src/components/Search/Common/Filters/AbortApplyFiltersContext/utils';

const AbortApplyFiltersContextProvider: FC<PropsWithChildren> = ({ children }) => {
    const abortControllerRef = useRef(null);

    return <AbortApplyFiltersContext.Provider value={abortControllerRef}>{children}</AbortApplyFiltersContext.Provider>;
};

export default AbortApplyFiltersContextProvider;
