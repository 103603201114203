import { DefaultRootState } from 'react-redux';
import { AxiosError } from 'axios';
import { FORBIDDEN } from 'http-status-codes';
import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';

import validate, {
    InvalidWidget,
    isInvalidWidget,
    ConstructorWidgetValidateError,
} from 'src/components/EmployerConstructor/validate';
import {
    employerConstructorEditForbiddenError,
    employerConstructorSaveTemplateError,
    employerConstructorSaveTemplateOk,
} from 'src/components/Notifications/EmployerConstructor';
import { AddNotification } from 'src/components/Notifications/Provider/types';
import { CountryId } from 'src/models/countryId';
import {
    employerConstructorModifyWidget,
    employerConstructorSetSaved,
    setConstructorStatus,
    Status,
    EmployerConstructorWidget,
} from 'src/models/employerConstructor';
import WidgetType from 'src/models/employerConstructor/widget.types';
import { openConnectReviewsModal } from 'src/models/employerReviews/employerReviewsModal';
import fetcher from 'src/utils/fetcher';

const EMPLOYER_CONSTRUCTOR_SAVE_TEMPLATE = '/employer/constructor/save_template';

declare global {
    interface FetcherPostApi {
        [EMPLOYER_CONSTRUCTOR_SAVE_TEMPLATE]: {
            queryParams: void;
            body: {
                headerBackgroundPictureId?: number | null;
                sidebarColors: Record<string, string>;
                widgets: EmployerConstructorWidget[];
            };
            response: void;
        };
    }
}

const sortWidgets = <W extends EmployerConstructorWidget | InvalidWidget>(widgets: W[], sortIds: number[]): W[] => {
    if (sortIds) {
        widgets.sort((widgetA, widgetB) => {
            const widgetAId = isInvalidWidget(widgetA) ? widgetA.widget?.id : widgetA.id;
            const widgetBId = isInvalidWidget(widgetB) ? widgetB.widget?.id : widgetB.id;
            return sortIds.indexOf(widgetAId) - sortIds.indexOf(widgetBId);
        });
    }

    return widgets;
};

const getOnlyValidWidgets = (widgets: EmployerConstructorWidget[], errorWidgets: InvalidWidget[]) => {
    const errorWidgetsIds = errorWidgets.map(({ widget }) => widget.id);
    return widgets.filter((widget) => !errorWidgetsIds.includes(widget.id));
};

type SaveConstructorTermplate = (
    addNotification: AddNotification
) => ThunkAction<Promise<void> | null, DefaultRootState, unknown, AnyAction>;

const saveConstructorTemplate: SaveConstructorTermplate = (addNotification) => (dispatch, getState) => {
    const { employerConstructor, employerConstructorSettings, employerAddresses } = getState();

    const errorWidgets = validate({
        ...employerConstructor,
        settings: employerConstructorSettings,
        addresses: employerAddresses,
    });

    /**
     * если среди невалидных виджетов есть текст с превышением длины - запрос не будет отправлен,
     * и первый найденный компонент (среди отсортированных в порядке расположения на странице) сделает скролл на себя
     */
    const sortedErrorWidgets = sortWidgets([...errorWidgets], employerConstructor.widgetsOrder);
    const constructorHasInvalidTextWidget = sortedErrorWidgets.some(({ widget, error }) => {
        const isInvalidTextWidget =
            widget.type === WidgetType.Text && error === ConstructorWidgetValidateError.InvalidLength;
        if (isInvalidTextWidget) {
            dispatch(
                employerConstructorModifyWidget({
                    id: widget.id,
                    invalidOnSave: true,
                    invalid: true,
                })
            );
        }
        return isInvalidTextWidget;
    });
    if (constructorHasInvalidTextWidget) {
        return null;
    }

    // сортируем widgets по widgetsOrder, выбираем оттуда только валидные виджеты
    const sortedWidgets = sortWidgets([...employerConstructor.widgets], employerConstructor.widgetsOrder);
    const validWidgets = getOnlyValidWidgets(sortedWidgets, errorWidgets);

    const requestData = {
        headerBackgroundPictureId: employerConstructor.pictureId,
        sidebarColors: employerConstructor.sidebarColors,
        widgets: validWidgets,
    };

    dispatch(setConstructorStatus(Status.Fetching));
    return fetcher.post(EMPLOYER_CONSTRUCTOR_SAVE_TEMPLATE, requestData).then(
        () => {
            const isRussia = getState().countryId === CountryId.Russia;
            openConnectReviewsModal(isRussia, employerConstructor, validWidgets);

            dispatch([setConstructorStatus(Status.Success), employerConstructorSetSaved()] as unknown as AnyAction);
            addNotification(employerConstructorSaveTemplateOk);
        },
        (error: AxiosError) => {
            dispatch(setConstructorStatus(Status.Fail));
            // TODO: почему не сохранилось?
            if (error.response?.status === FORBIDDEN) {
                addNotification(employerConstructorEditForbiddenError);
            } else {
                addNotification(employerConstructorSaveTemplateError);
            }
        }
    );
};

export default saveConstructorTemplate;
