import { FC } from 'react';

import VacancySearchItem from 'src/components/VacancySearchItem';
import Source from 'src/components/VacancySearchItem/types/Source';
import { useSelector } from 'src/hooks/useSelector';

import NoVacancies from 'src/components/Employer/VacancySearch/NoVacancies';
import Pagination from 'src/components/Employer/VacancySearch/Pagination';
import SearchContentLoader from 'src/components/Employer/VacancySearch/SearchContentLoader';

interface SearchContentProps {
    scrollWidgetTop: () => void;
}

const SearchContent: FC<SearchContentProps> = ({ scrollWidgetTop }) => {
    const vacancies = useSelector(({ employerVacancySearch }) => employerVacancySearch.vacancies);
    const resultsFound = useSelector(({ employerVacancySearch }) => employerVacancySearch.resultsFound);
    const isLoading = useSelector(({ employerVacancySearch }) => employerVacancySearch.isLoading);

    if (isLoading || resultsFound === undefined) {
        return <SearchContentLoader />;
    }

    if (!resultsFound) {
        return <NoVacancies />;
    }

    return (
        <>
            {vacancies.map((vacancy) => (
                <VacancySearchItem
                    key={vacancy.vacancyId}
                    vacancy={vacancy}
                    vacancySource={Source.EmployerVacancySearch}
                />
            ))}
            <Pagination scrollWidgetTop={scrollWidgetTop} />
        </>
    );
};

export default SearchContent;
