import { PropsWithChildren, forwardRef } from 'react';

import employerWantWorkHereButtonClick from '@hh.ru/analytics-js-events/build/xhh/applicant/branding/employer_page/employer_want_work_here_button_click';
import { Button } from '@hh.ru/magritte-ui';
import { BriefcaseOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { Link } from '@hh.ru/redux-spa-middleware';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { sendAnalyticsWantWorkHere } from 'Modules/ApplicantAnalytics';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { UserType } from 'src/models/userType';

const sendAnalytics = (userType: UserType, employerId: number) => () => {
    if (userType === UserType.Anonymous || userType === UserType.Applicant) {
        sendAnalyticsWantWorkHere(`${employerId}`);
    }
};

export const WantWorkHereLink = forwardRef<HTMLAnchorElement, PropsWithChildren>(({ children, ...props }, ref) => {
    const userType = useSelector((state) => state.userType);
    const employerId = useSelector((state) => state.employerInfo.id);
    const hasWantWorkService = useSelector((state) => state.employerButtons.workHere);
    const isZpEmployer = useSelector((state) => state.employerInfo.isZpEmployer);

    if (isZpEmployer || !hasWantWorkService || !employerId) {
        return null;
    }

    return (
        <Link
            to={`/applicant/wantwork/payment?employerId=${employerId}&from=employer_sidebar&hhtmFromLabel=employer_sidebar`}
            data-qa="resumeservice-button__targetemployer"
            onClick={() => employerWantWorkHereButtonClick({ employerId: String(employerId) })}
            onMouseUp={sendAnalytics(userType, employerId)}
            ref={ref}
            {...props}
        >
            {children}
        </Link>
    );
});

const TrlKeys = {
    employerViewNeedWork: 'employer.page.wantWork',
};

interface WantWorkHereProps {
    stretched?: boolean;
    isMenuItem?: boolean;
}

const WantWorkHere: TranslatedComponent<WantWorkHereProps> = ({ trls, stretched, isMenuItem }) => (
    <Button
        size={isMenuItem ? 'medium' : 'small'}
        style="neutral"
        mode={isMenuItem ? 'tertiary' : 'secondary'}
        stretched={stretched}
        Element={WantWorkHereLink}
        icon={isMenuItem ? <BriefcaseOutlinedSize24 /> : undefined}
    >
        {trls[TrlKeys.employerViewNeedWork]}
    </Button>
);

export default translation(WantWorkHere);
