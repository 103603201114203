import { FC } from 'react';

import { Card, VSpacing } from '@hh.ru/magritte-ui';
import Column, { ColumnsWrapper } from 'bloko/blocks/column';

import EmployerPageTabContent from 'src/components/Employer/PageTabs/EmployerPageTabContent';
import { EmployerPageTab } from 'src/components/Employer/PageTabs/PageTabsContext';
import EmployerPageTitle, { TileMode } from 'src/components/Employer/PageTitle';
import VacanciesGroupsByRegion from 'src/components/Employer/VacanciesGroupsByRegion';
import SearchFooterButton from 'src/components/Employer/VacancySearch/SearchFooterButton';
import Widgets from 'src/components/EmployerConstructor/WidgetsList';
import ConnectReviewsModal from 'src/components/EmployerReviews/ConnectReviewsModal';
import EmployerReviewsWidgetBig from 'src/components/EmployerReviews/WidgetBig';
import { useIsRussia } from 'src/hooks/useCountries';
import { useIsZarplataPlatform } from 'src/hooks/usePlatform';
import { useSelector } from 'src/hooks/useSelector';

import Header from 'src/components/Employer/EmployerView/Header';
import EmptyDescription from 'src/components/Employer/EmployerView/Header/EmptyDescription';
import RightPanel from 'src/components/Employer/EmployerView/RightPanel';

const EmployerView: FC = () => {
    const employerName = useSelector((state) => state.employerInfo.name);
    const isRussia = useIsRussia();
    const isZP = useIsZarplataPlatform();
    const employerAccepted = useSelector(({ employerInfo }) => employerInfo.accepted);
    const descriptionText = useSelector((state) => state.employerInfo.description);
    const showReviewsWidget = !isZP && employerAccepted;
    const hasDescription = !!descriptionText;

    return (
        <EmployerPageTitle mode={TileMode.View} employerName={employerName}>
            <Header />
            <EmployerPageTabContent tab={EmployerPageTab.Description}>
                <ColumnsWrapper>
                    <Column xs="4" s="8" m="8" l="10">
                        {hasDescription ? (
                            <Widgets />
                        ) : (
                            <>
                                <VSpacing default={40} />
                                <Card padding={24} showBorder borderRadius={24} stretched>
                                    <EmptyDescription />
                                </Card>
                            </>
                        )}
                        <VSpacing default={24} />
                        {showReviewsWidget && <EmployerReviewsWidgetBig />}
                    </Column>
                    <Column xs="4" s="8" m="4" l="6">
                        <VSpacing default={40} />
                        <RightPanel />
                    </Column>
                </ColumnsWrapper>
            </EmployerPageTabContent>
            <EmployerPageTabContent tab={EmployerPageTab.Vacancies}>
                <ColumnsWrapper>
                    <Column xs="4" s="8" m="12" l="16">
                        <VSpacing default={24} />
                        <VacanciesGroupsByRegion />
                    </Column>
                </ColumnsWrapper>
            </EmployerPageTabContent>
            <SearchFooterButton />
            <VSpacing default={24} />
            {isRussia && !isZP && <ConnectReviewsModal />}
        </EmployerPageTitle>
    );
};

export default EmployerView;
