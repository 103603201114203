import { useCallback, useMemo } from 'react';

import { translation } from '@hh.ru/front-static-app';
import { Card, Text } from '@hh.ru/magritte-ui';
import {
    CompanysLifeOutlinedSize24,
    DreamjobSize24,
    EmployersRatingOutlinedSize24,
    HrBrandOutlinedSize24,
    ItAccreditationSize24,
} from '@hh.ru/magritte-ui/product';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { format } from 'bloko/common/trl';

import useGetCompanyHHRatingBadge from 'src/hooks/companies/useGetCompanyHHRatingBadge';
import { useSelector } from 'src/hooks/useSelector';
import { BadgeType } from 'src/models/vacancyView.types';

import ItemWithTooltip from 'src/components/Employer/EmployerView/RightPanel/ItemWithTooltip';

import styles from './right-panel.less';

const TrlKeys = {
    itAccreditation: {
        title: 'employerView.rightPanel.itAccreditation.title',
        subtitle: 'employerView.rightPanel.itAccreditation.subtitle',
        description: 'employerView.rightPanel.itAccreditation.description',
        button: 'employerView.rightPanel.itAccreditation.tooltip.button',
    },
    openEmployer: {
        title: 'employerView.rightPanel.openEmployer.title',
        subtitle: 'employerView.rightPanel.openEmployer.subtitle',
        description: 'employerView.rightPanel.openEmployer.description',
        button: 'employerView.rightPanel.openEmployer.tooltip.button',
    },
    employerHHRating: {
        subtitle: 'employerView.rightPanel.employerHHRating.subtitle',
        description1: 'employerView.rightPanel.employerHHRating.description1',
        // TODO: добавить при раскатке экспа, когда category будет приходить с бэка
        description2: 'employerView.rightPanel.employerHHRating.description2',
        button: 'employerView.rightPanel.employerHHRating.tooltip.button',
        rating: {
            top10: 'employerView.rightPanel.employerHHRating.title.top10',
            top20: 'employerView.rightPanel.employerHHRating.title.top20',
            top50: 'employerView.rightPanel.employerHHRating.title.top50',
            top100: 'employerView.rightPanel.employerHHRating.title.top100',
            top200: 'employerView.rightPanel.employerHHRating.title.top200',
            member: 'employerView.rightPanel.employerHHRating.title.member',
        },
    },
    hrBrand: {
        subtitle: 'employerView.rightPanel.hrBrand.subtitle',
        // TODO: при раскатке появится description2
        description1: 'employerView.rightPanel.hrBrand.description1',
        button: 'employerView.rightPanel.hrBrand.tooltip.button',
        winner: 'employerView.rightPanel.hrBrand.winner',
        nominee: 'employerView.rightPanel.hrBrand.nominee',
        and: 'employerView.rightPanel.hrBrand.and',
    },
    interview: {
        title: 'employerView.rightPanel.interview.title',
        subtitle: 'employerView.rightPanel.interview.subtitle',
        description: 'employerView.rightPanel.interview.description',
        button: 'employerView.rightPanel.interview.tooltip.button',
    },
};

const ratings = [20, 50, 100, 200] as const;

type HrBrandPosition = 'winner' | 'nominee';

const isHrBrandPosition = (value?: string): value is HrBrandPosition => {
    return value === 'winner' || value === 'nominee';
};

const capitalizeFirstLetter = (word: string) => word.charAt(0).toUpperCase() + word.slice(1);

// it-accreditation
// dreamjob
// hr-brand
// employers-rating
// интервью
// TODO: спецпроекты при раскатке экспа

const Advantages: TranslatedComponent = ({ trls }) => {
    const accreditedITEmployer = useSelector((state) => state.employerInfo.accreditedITEmployer);
    const isOpenEmployer = useSelector((state) => state.employerReviewsIsOpenEmployer);
    const hrBrand = useSelector((state) => state.employerInfo.hrBrand);
    const badges = useSelector((state) => state.employerInfo.badges);
    const insider = useSelector((state) => state.employerInsiderInterview);
    const hasInsiderArticles = !!insider?.articles?.length;

    const employerHHRatingBadge = useGetCompanyHHRatingBadge(badges);

    const getRatingDescription = useCallback(
        (positionStr?: string) => {
            if (!positionStr) {
                return '';
            }
            const position = parseInt(positionStr, 10);

            if (position <= 10) {
                return format(trls[TrlKeys.employerHHRating.rating.top10], { '{}': position });
            }

            const rating = ratings.find((rating) => position <= rating);
            return rating
                ? trls[TrlKeys.employerHHRating.rating[`top${rating}`]]
                : trls[TrlKeys.employerHHRating.rating.member];
        },
        [trls]
    );

    const hrBrandTitle = useMemo(() => {
        if (!badges) {
            return '';
        }
        const hrBrandPositions = badges.badge
            .filter((badge) => badge.type === BadgeType.HrBrand && isHrBrandPosition(badge.position))
            .map((badge) => trls[TrlKeys.hrBrand[badge.position as HrBrandPosition]]);

        return capitalizeFirstLetter(hrBrandPositions.join(` ${trls[TrlKeys.hrBrand.and]} `));
    }, [badges, trls]);

    if (!(accreditedITEmployer || isOpenEmployer || hrBrand || employerHHRatingBadge || hasInsiderArticles)) {
        return null;
    }

    return (
        <Card padding={24} showBorder borderRadius={24} stretched data-scroll-target="employer-advantages">
            <div className={styles.items}>
                {accreditedITEmployer && (
                    <ItemWithTooltip
                        style="accent"
                        icon={<ItAccreditationSize24 initial="accent" />}
                        tooltipButtonText={trls[TrlKeys.itAccreditation.button]}
                        tooltipDescription={trls[TrlKeys.itAccreditation.description]}
                        title={trls[TrlKeys.itAccreditation.title]}
                        subtitle={trls[TrlKeys.itAccreditation.subtitle]}
                        url="https://feedback.hh.ru/knowledge-base/article/00038"
                    />
                )}
                {isOpenEmployer && (
                    <ItemWithTooltip
                        style="accent"
                        icon={<DreamjobSize24 initial="accent" />}
                        tooltipButtonText={trls[TrlKeys.openEmployer.button]}
                        tooltipDescription={trls[TrlKeys.openEmployer.description]}
                        title={trls[TrlKeys.openEmployer.title]}
                        subtitle={trls[TrlKeys.openEmployer.subtitle]}
                    />
                )}
                {employerHHRatingBadge && (
                    <ItemWithTooltip
                        style="accent"
                        icon={<EmployersRatingOutlinedSize24 initial="accent" />}
                        tooltipButtonText={trls[TrlKeys.employerHHRating.button]}
                        tooltipDescription={<Text>{trls[TrlKeys.employerHHRating.description1]}</Text>}
                        title={getRatingDescription(employerHHRatingBadge.position)}
                        subtitle={trls[TrlKeys.employerHHRating.subtitle]}
                        url={employerHHRatingBadge.url}
                    />
                )}
                {hrBrand && (
                    <ItemWithTooltip
                        style="accent"
                        icon={<HrBrandOutlinedSize24 initial="accent" />}
                        tooltipButtonText={trls[TrlKeys.hrBrand.button]}
                        tooltipDescription={<Text>{trls[TrlKeys.hrBrand.description1]}</Text>}
                        title={hrBrandTitle}
                        subtitle={trls[TrlKeys.hrBrand.subtitle]}
                        url="https://hrbrand.ru"
                    />
                )}
                {hasInsiderArticles && (
                    <ItemWithTooltip
                        style="accent"
                        icon={<CompanysLifeOutlinedSize24 initial="accent" />}
                        tooltipButtonText={trls[TrlKeys.interview.button]}
                        tooltipDescription={<Text>{trls[TrlKeys.interview.description]}</Text>}
                        title={trls[TrlKeys.interview.title]}
                        subtitle={trls[TrlKeys.interview.subtitle]}
                        url={`/interview/${insider?.articles?.[0]?.code}?employerId=${insider?.employerId}`}
                    />
                )}
            </div>
        </Card>
    );
};

export default translation(Advantages);
