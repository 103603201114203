import { FC } from 'react';

import { Text } from '@hh.ru/magritte-ui';

import styles from './separator.less';

const Separator: FC = () => {
    return (
        <div className={styles.root}>
            <Text typography="label-2-regular">·</Text>
        </div>
    );
};

export default Separator;
