import { DefaultRootState } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';

import defaultRequestErrorHandler from 'src/api/notifications/defaultRequestErrorHandler';
import {
    employerLogoUploadError,
    employerLogoUploadSuccess,
    employerLogoUploadTimeout,
} from 'src/components/Notifications/EmployerSidebar';
import Snackbar from 'src/components/Notifications/Notification';
import { AddNotification } from 'src/components/Notifications/Provider/types';
import { setLogoStatus, setLogoUrl, Status } from 'src/models/employer/employerLogo';
import fetcher from 'src/utils/fetcher';

const CHECK_STATUS_URL = '/employer/edit/logo';
enum UploadLogoState {
    Generated = 'GENERATED',
    Failed = 'FAILED',
}
declare global {
    interface FetcherGetApi {
        [CHECK_STATUS_URL]: {
            queryParams: { t: string };
            response: { state: UploadLogoState; url: string };
        };
    }
}

const CHECK_TIMEOUT_MS = 200;
const MAX_CHECK_ATTEMPTS = 20;

const uploadLogoCheckStatus =
    (
        addNotification: AddNotification,
        handleExitModal: () => void,
        attempts = MAX_CHECK_ATTEMPTS
    ): ThunkAction<Promise<unknown>, DefaultRootState, unknown, AnyAction> =>
    (dispatch, getState) => {
        dispatch(setLogoStatus(Status.Fetching));
        return fetcher.get(CHECK_STATUS_URL, { params: { t: `${Date.now()}` } }).then(
            (data) => {
                const employerLogoEditSettings = getState().employerLogoEditSettings;
                if (!employerLogoEditSettings) {
                    return;
                }
                if (data.state === 'GENERATED') {
                    handleExitModal();
                    dispatch([setLogoUrl(data.url), setLogoStatus(Status.Success)] as unknown as AnyAction);
                    addNotification(Snackbar, employerLogoUploadSuccess);
                } else if (data.state === 'FAILED') {
                    dispatch(setLogoStatus(Status.Fail));
                    handleExitModal();
                    addNotification(
                        Snackbar,
                        employerLogoUploadError({
                            logoMaxWidth: employerLogoEditSettings.maxWidth,
                            logoMaxHeight: employerLogoEditSettings.maxHeight,
                        })
                    );
                } else if (attempts < 0) {
                    dispatch(setLogoStatus(Status.Fail));
                    handleExitModal();
                    addNotification(Snackbar, employerLogoUploadTimeout);
                } else {
                    setTimeout(() => {
                        dispatch(uploadLogoCheckStatus(addNotification, handleExitModal, attempts - 1)).catch(
                            console.error
                        );
                    }, CHECK_TIMEOUT_MS);
                }
            },
            (error) => {
                dispatch(setLogoStatus(Status.Fail));
                defaultRequestErrorHandler(error, addNotification);
            }
        );
    };

export default uploadLogoCheckStatus;
