import { ComponentPropsWithoutRef, forwardRef } from 'react';

import { useSubscribeContext } from 'src/components/Employer/Subscribe/SubscribeContext';

const Subscribe = forwardRef<HTMLButtonElement, ComponentPropsWithoutRef<'button'>>(
    ({ children, onClick, ...props }, ref) => {
        const { handleSaveClick, saveSearchSubmitDisabled } = useSubscribeContext() || {};

        return (
            <button
                {...props}
                onClick={(e) => {
                    void handleSaveClick();
                    onClick?.(e);
                }}
                disabled={saveSearchSubmitDisabled}
                ref={ref}
            >
                {children}
            </button>
        );
    }
);

export default Subscribe;
