import Analytics from '@hh.ru/analytics-js';
import { Button } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { EmployerPageTab, usePageTabsContext } from 'src/components/Employer/PageTabs/PageTabsContext';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

const TrlKeys = {
    buttonText: 'employer.view.chameleon.vacancies.filtered.link',
};

const VacanciesLink: TranslatedComponent = ({ trls }) => {
    const employerId = useSelector(({ employerInfo }) => employerInfo.id);
    const { setCurrentTab } = usePageTabsContext();
    const isLoading = useSelector((state) => state.employerVacancySearch.isLoading && state.showEmployerVacancySearch);

    return (
        <Button
            size="medium"
            style="neutral"
            loading={isLoading}
            mode="secondary"
            stretched
            data-qa="employer-page-tabs-xs-go-filtered-vacancies"
            onClick={() => {
                setCurrentTab(EmployerPageTab.Vacancies);
                Analytics.sendHHEventButtonClick('vacancies_in_current_department_button_click', {
                    employerId,
                    isWebview: false,
                });
                window.scrollTo(0, 0);
            }}
        >
            {trls[TrlKeys.buttonText]}
        </Button>
    );
};

export default translation(VacanciesLink);
