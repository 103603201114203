import { FC } from 'react';

import ContainerForMicroFrontend from 'src/components/CodeInjector/ContainerForMicroFrontend';
import useResumesCount from 'src/components/Employer/EmployerView/Header/hooks/useResumesCount';
import WantWorkHere from 'src/components/Employer/Sidebar/WantWorkHere';
import { useSelector } from 'src/hooks/useSelector';

import Subscribe from 'src/components/Employer/EmployerView/Header/Actions/Subscribe';
import WantWorkHereAlready from 'src/components/Employer/EmployerView/Header/Actions/WantWorkHereAlready';

import styles from './actions.less';

const DesktopActions: FC = () => {
    const employerButtons = useSelector((state) => state.employerButtons);
    const { countResumes, visibleResumesCount } = useResumesCount();

    return (
        <div className={styles.root}>
            {!!employerButtons.saveSearch && <Subscribe stretched />}
            {(visibleResumesCount !== countResumes || visibleResumesCount === 0) && <WantWorkHere stretched />}
            {countResumes > 0 && <WantWorkHereAlready />}
            {!employerButtons.complain?.success && <ContainerForMicroFrontend place="employerComplainMini" />}
        </div>
    );
};

export default DesktopActions;
