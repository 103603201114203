import { ReactNode, useRef, useState } from 'react';

import { BottomSheet, BottomSheetFooter, Button, TooltipHover } from '@hh.ru/magritte-ui';
import { Link } from '@hh.ru/redux-spa-middleware';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';

import Item, { ItemProps } from 'src/components/Employer/EmployerView/RightPanel/Item';

interface ItemWithTooltipProps extends ItemProps {
    tooltipButtonText: string;
    tooltipDescription: ReactNode;
    url?: string;
}

const TrlKeys = {
    close: 'employerView.rightPanel.bottomSheet.close',
};

const ItemWithTooltip: TranslatedComponent<ItemWithTooltipProps> = ({
    tooltipButtonText,
    tooltipDescription,
    style,
    url,
    trls,
    ...props
}) => {
    const activatorRef = useRef(null);
    const [visible, setVisible] = useState(false);

    const TooltipButton = url ? (
        <Button Element={Link} to={url} target="_blank" mode="secondary" style="contrast" size="small" stretched>
            {tooltipButtonText}
        </Button>
    ) : (
        <Button mode="secondary" style="contrast" size="small" stretched>
            {tooltipButtonText}
        </Button>
    );

    const CloseButton = (
        <Button mode="secondary" stretched onClick={() => setVisible(false)} size="small">
            {trls[TrlKeys.close]}
        </Button>
    );

    return (
        <>
            <BottomSheet
                visible={visible}
                onClose={() => setVisible(false)}
                footer={<BottomSheetFooter>{[TooltipButton, CloseButton]}</BottomSheetFooter>}
            >
                {tooltipDescription}
            </BottomSheet>
            <TooltipHover activatorRef={activatorRef} placement="top-center" buttons={TooltipButton}>
                {tooltipDescription}
            </TooltipHover>
            <Item ref={activatorRef} style={style} onClick={() => setVisible(true)} {...props} />
        </>
    );
};

export default translation(ItemWithTooltip);
