import Analytics, { useElementShown } from '@hh.ru/analytics-js';
import { ActionBar, Modal, Button, VSpacing, Text } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

interface ImageViewModalProps {
    onModalClose: () => void;
    visible: boolean;
}

const TrlKeys = {
    title: 'employer.logoEdit.modal.communication.title',
    description: 'employer.logoEdit.modal.communication.description',
    primaryAction: 'employer.logoEdit.modal.communication.primaryAction',
};

const ImageViewModal: TranslatedComponent<ImageViewModalProps> = ({ trls, onModalClose, visible }) => {
    const staticHost = useSelector((state) => state.config.staticHost);
    const communicationShownRef = useElementShown(Analytics.sendHHEventElementShown, {
        name: 'round_logo_communication_info_modal',
    });

    const handleCloseModal = () => {
        Analytics.sendHHEvent('round_logo_communication_info_modal_close');
        onModalClose();
    };

    const renderContent = () => {
        return (
            <div ref={communicationShownRef}>
                <Text style="primary" typography="title-3-semibold">
                    {trls[TrlKeys.title]}
                </Text>
                <VSpacing default={8} />
                <Text style="secondary" typography="paragraph-1-regular">
                    {trls[TrlKeys.description]}
                </Text>
            </div>
        );
    };

    return (
        <Modal
            visible={visible}
            onClose={handleCloseModal}
            size="large"
            headerImage={`${staticHost}/images/hh/employer/logo/round-logo.gif`}
            headerHeight={242}
            footer={
                <ActionBar
                    primaryActions={
                        <Button size="medium" style="accent" mode="primary" onClick={handleCloseModal}>
                            {trls[TrlKeys.primaryAction]}
                        </Button>
                    }
                />
            }
        >
            {renderContent()}
        </Modal>
    );
};

export default translation(ImageViewModal);
