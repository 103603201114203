import { translation } from '@hh.ru/front-static-app';
import { Card } from '@hh.ru/magritte-ui';
import { LinkOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { useSelector } from 'src/hooks/useSelector';

import Item from 'src/components/Employer/EmployerView/RightPanel/Item';

const TrlKeys = {
    site: 'employerView.rightPanel.companyInfo.site',
};

const CompanySite: TranslatedComponent = ({ trls }) => {
    const site = useSelector((state) => state.employerInfo.site);
    const accepted = useSelector((state) => state.employerInfo.accepted);

    if (!(site && accepted)) {
        return null;
    }

    return (
        <Card padding={24} showBorder borderRadius={24} stretched>
            <Item
                style="accent"
                icon={<LinkOutlinedSize24 initial="accent" />}
                title={site.href}
                subtitle={trls[TrlKeys.site]}
                titleAsLink
            />
        </Card>
    );
};

export default translation(CompanySite);
