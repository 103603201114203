import { translation } from '@hh.ru/front-static-app';
import { Button } from '@hh.ru/magritte-ui';
import { CheckOutlinedSize16, CheckOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { Link } from '@hh.ru/redux-spa-middleware';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

const TrlKeys = {
    already: 'employerView.header.wantWorkHere.already',
};

const WantWorkHereAlready: TranslatedComponent<{ isMenuItem?: boolean }> = ({ trls, isMenuItem }) => {
    return (
        <Button
            Element={Link}
            to="/applicant-services/services"
            size={isMenuItem ? 'medium' : 'small'}
            style="neutral"
            stretched
            mode={isMenuItem ? 'tertiary' : 'secondary'}
            icon={isMenuItem ? <CheckOutlinedSize24 /> : <CheckOutlinedSize16 />}
        >
            {trls[TrlKeys.already]}
        </Button>
    );
};

export default translation(WantWorkHereAlready);
