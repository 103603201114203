import {
    forwardRef,
    HTMLAttributes,
    cloneElement,
    useCallback,
    ForwardRefRenderFunction,
    ReactElement,
    MouseEvent,
    TouchEvent,
} from 'react';
import classnames from 'classnames';

import { UniversalDragEvent } from 'src/components/EmployerConstructor/drag/dragHelpers';

import styles from './drag-element.less';

export type GetMovedElementProps = () => Pick<HTMLAttributes<HTMLElement>, 'onMouseDown' | 'onTouchStart'>;
export interface DragParams {
    getMovedElementProps?: GetMovedElementProps;
    dragged?: boolean;
    hasDraggedElements?: boolean;
}

type UniversalSyntheticDragEvent = MouseEvent<HTMLElement> | TouchEvent<HTMLElement>;

interface DragElementProps {
    children: ReactElement;
    active?: boolean;
    index: number;
    onDragStart: (event: UniversalDragEvent, index: number) => void;
    hasDraggedElements?: boolean;
}

const DragElement: ForwardRefRenderFunction<HTMLDivElement, DragElementProps> = (
    { children, onDragStart, index, active, hasDraggedElements },
    ref
) => {
    const onDragStartWrapper = useCallback(
        (event: UniversalSyntheticDragEvent) => {
            onDragStart(event.nativeEvent, index);
        },
        [index, onDragStart]
    );

    const getMovedElementProps: GetMovedElementProps = useCallback(() => {
        return {
            onMouseDown: onDragStartWrapper,
            onTouchStart: onDragStartWrapper,
        };
    }, [onDragStartWrapper]);

    return (
        <div ref={ref} className={classnames({ [styles.constructorDragElementActive]: active })}>
            {cloneElement(children, { getMovedElementProps, dragged: active, hasDraggedElements })}
        </div>
    );
};

export default forwardRef(DragElement);
