import { AreaSelection } from 'src/models/employerConstructor';

const useImageCropProcess = (
    canvasElement: HTMLCanvasElement | null,
    setImage: (img: string) => void,
    handleSetDimensions: (width: number, height: number) => void
): {
    drawImageWithBackground: (originalImage: HTMLImageElement) => void;
    getCroppedImage: (imageSrc: string, absoluteSize: AreaSelection) => Promise<File>;
} => {
    const drawImageWithBackground = (originalImage: HTMLImageElement) => {
        if (canvasElement) {
            const canvas = canvasElement;
            const context = canvas.getContext('2d');
            canvas.width = originalImage.width;
            canvas.height = originalImage.height;
            if (context) {
                context.fillStyle = 'white';
                context.fillRect(0, 0, canvas.width, canvas.height);

                context.drawImage(originalImage, 0, 0);

                setImage(canvas.toDataURL());
                handleSetDimensions(canvas.width, canvas.height);
            } else {
                setImage(originalImage.src);
                handleSetDimensions(originalImage.width, originalImage.height);
            }
        }
    };

    const getCroppedImage = async (imageSrc: string, absoluteSize: AreaSelection): Promise<File> => {
        const loadImage = (src: string): Promise<HTMLImageElement> => {
            return new Promise((resolve, reject) => {
                const image = new Image();
                image.src = src;
                image.onload = () => resolve(image);
                image.onerror = () => reject(new Error('loadImage error'));
            });
        };

        const getFileFromCanvas = (canvas: HTMLCanvasElement): Promise<File> => {
            return new Promise((resolve, reject) => {
                canvas.toBlob((blob) => {
                    if (!blob) {
                        reject(new Error('Error while processing image getFileFromCanvas'));
                        return;
                    }
                    resolve(new File([blob], 'image.png', { type: 'image/png' }));
                }, 'image/png');
            });
        };

        try {
            const image = await loadImage(imageSrc);

            if (!canvasElement) {
                throw new Error('Error while processing image no canvasElement');
            }

            const canvas = canvasElement;
            const context = canvas.getContext('2d');

            if (!context) {
                throw new Error('Error while processing image no context');
            }

            const cropSize = Math.min(absoluteSize.width, absoluteSize.height);
            canvas.width = cropSize;
            canvas.height = cropSize;

            context.beginPath();
            context.arc(cropSize / 2, cropSize / 2, cropSize / 2, 0, Math.PI * 2, true);
            context.closePath();
            context.clip();
            context.fill();
            context.globalCompositeOperation = 'source-in';

            context.drawImage(
                image,
                absoluteSize.x,
                absoluteSize.y,
                absoluteSize.width,
                absoluteSize.height,
                0,
                0,
                cropSize,
                cropSize
            );

            return await getFileFromCanvas(canvas);
        } catch (error) {
            throw new Error(error instanceof Error ? error.message : String(error));
        }
    };

    return {
        drawImageWithBackground,
        getCroppedImage,
    };
};

export default useImageCropProcess;
