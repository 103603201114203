import { useCallback } from 'react';

import { ElementShownAnchor } from '@hh.ru/analytics-js';
import employerComplaintCreationElementShown from '@hh.ru/analytics-js-events/build/xhh/applicant/employer_complaint/employer_complaint_creation_element_shown';
import { translation } from '@hh.ru/front-static-app';
import { Button } from '@hh.ru/magritte-ui';
import { ExclamationTriangleOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { useSelector } from 'src/hooks/useSelector';

const TrlKeys = {
    button: 'employer.complain.menuItem.button',
};

const Complain: TranslatedComponent<{ onClick?: VoidFunction }> = ({ trls, onClick }) => {
    const employerId = useSelector(({ employerInfo }) => employerInfo.id);

    const handleClick = useCallback(() => {
        onClick?.();
        window.dispatchEvent(new CustomEvent('ON_WIZARD_OPEN'));
    }, [onClick]);

    return (
        <ElementShownAnchor fn={employerComplaintCreationElementShown} employerId={Number(employerId)}>
            <Button
                stretched
                mode="tertiary"
                style="neutral"
                onClick={handleClick}
                data-qa="employer-vacancy-complain-button-menu-item"
                icon={<ExclamationTriangleOutlinedSize24 />}
            >
                {trls[TrlKeys.button]}
            </Button>
        </ElementShownAnchor>
    );
};

export default translation(Complain);
