import { useCallback, useMemo, useState } from 'react';

import { useSetEmployerVacancyListDefault } from 'src/api/employerVacancySearch/useSetEmployerVacancies';
import { useSelector } from 'src/hooks/useSelector';

import { EmployerPageTab, isEmployerPageTab } from 'src/components/Employer/PageTabs/PageTabsContext';

type UseCurrentTab = () => { currentTab: EmployerPageTab; setCurrentTab: (newTab: string) => void };

const useCurrentTab: UseCurrentTab = () => {
    const showEmployerVacancySearch = useSelector(({ showEmployerVacancySearch }) => showEmployerVacancySearch);
    const { setEmployerVacancyListDefault } = useSetEmployerVacancyListDefault();
    const [currentTab, setCurrentTabLocal] = useState(EmployerPageTab.Description);

    const setCurrentTab = useCallback(
        (newTab: string) => {
            if (!isEmployerPageTab(newTab) || newTab === currentTab) {
                return;
            }
            showEmployerVacancySearch && newTab === EmployerPageTab.Vacancies && setEmployerVacancyListDefault();
            setCurrentTabLocal(newTab);
        },
        [currentTab, setEmployerVacancyListDefault, showEmployerVacancySearch]
    );

    return useMemo(() => ({ currentTab, setCurrentTab }), [currentTab, setCurrentTab]);
};

export default useCurrentTab;
