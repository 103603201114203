import React, { useEffect, FC, useRef, useCallback, useMemo } from 'react';
import classnames from 'classnames';

import throttle from 'bloko/common/throttle';

import DragablePoint from 'src/components/ImageCropPopup/Points/DragablePoint';
import { DraggedPointType, PointType } from 'src/components/ImageCropPopup/Points/pointType';
import { AreaSelection } from 'src/models/employerConstructor';

import styles from './image-crop.less';

const MOBILE_FRAME_RATIO = 1.43;

type SetAreaStateFunction = (deltaX: number, deltaY: number) => void;
const DEFAULT_AREA_RELATIVE_WIDTH = 0.8;
const DEFAULT_AREA_RELATIVE_HEIGHT = 0.8;
const DEFAULT_AREA_RELATIVE_X = 0.1;
const DEFAULT_AREA_RELATIVE_Y = 0.1;
const MINIMUM_AREA_SIZE_PX = 30;
const MOUSEMOVE_THROTTLE_DELAY_MS = 30;
const CONTAINER_MAXIMUM_HEIGHT_PX = 400;
const DEFAULT_CROP_RESULT = {
    relativeSizes: { x: 0, y: 0, width: 0, height: 0 },
    absoluteSizes: { x: 0, y: 0, width: 0, height: 0 },
    originalToAreaRatio: 0,
    noChanges: true,
};

export interface CropResult {
    relativeSizes: AreaSelection;
    absoluteSizes: AreaSelection;
    originalToAreaRatio: number;
    noChanges: boolean;
}

export interface ImageCropProps {
    /** src оригинала картинки */
    src: string;
    /** стартовый X относительно оригинального размера картинки */
    stateX: number;
    /** стартовый Y относительно оригинального размера картинки */
    stateY: number;
    /** стартовая ширина относительно оригинального размера картинки */
    stateWidth: number;
    /** стартовая высота относительно оригинального размера картинки */
    stateHeight: number;
    /** ширина оригинала */
    originalWidth: number;
    /** высота оригинала */
    originalHeight: number;
    /** пропорции размеров выбираемой области */
    ratio?: number;
    /** минимальная ширина для выбора области относительно оригинала */
    minimumWidth?: number;
    /** минимальная высота для выбора области относительно оригинала */
    minimumHeight?: number;
    /** максимальная ширина контейнера. Картинка внутри будет позиционироваться по центру */
    containerMaximumWidth?: number;
    /** максимальная высота контейнера. Картинка внутри будет позиционироваться по центру */
    containerMaximumHeight?: number;
    /** флаг отрисовки окна мобилки */
    hasFrameMobile?: boolean;
    /** для возврата получившихся значений */
    onResizeCallback?: (arg: CropResult | null) => void;
    /** для контроля процесса DragAndDrop */
    onDragStart?: () => void;
    /** для контроля процесса DragAndDrop */
    onDragStop?: () => void;
    isCircleMask?: boolean;
    pointType?: PointType;
    isCentered?: boolean;
}

const ImageCrop: FC<ImageCropProps> = ({
    src = '',
    stateX = 0,
    stateY = 0,
    stateWidth = 0,
    stateHeight = 0,
    ratio = 0,
    originalWidth = 1,
    originalHeight = 1,
    minimumWidth = MINIMUM_AREA_SIZE_PX,
    minimumHeight = MINIMUM_AREA_SIZE_PX,
    containerMaximumHeight = CONTAINER_MAXIMUM_HEIGHT_PX,
    containerMaximumWidth,
    onResizeCallback,
    onDragStart,
    onDragStop,
    hasFrameMobile,
    isCircleMask = false,
    isCentered = false,
    pointType = PointType.Circle,
}) => {
    const containerElement = useRef<HTMLDivElement>(null);
    const areaElement = useRef<HTMLDivElement>(null);
    const imageElement = useRef<HTMLImageElement>(null);
    const areaMobileFrameElement = useRef<HTMLImageElement>(null);
    const setAreaStateFunction = useRef<SetAreaStateFunction | null>(null);
    const areaState = useRef({ x: 0, y: 0, width: 0, height: 0 });
    const dragParams = useRef({
        clickX: 0,
        clickY: 0,
        savedAreaStateX: 0,
        savedAreaStateY: 0,
        savedAreaStateWidth: 0,
        savedAreaStateHeight: 0,
    });
    const containerSize = useRef({ width: 0, height: 0 });
    const originalToAreaRatio = useRef(1);

    const handleCallResizeCallback = useCallback(() => {
        onResizeCallback?.({
            noChanges: false,
            relativeSizes: { ...areaState.current },
            absoluteSizes: {
                // округление обязательно в меньшую сторону, чтобы не вылезало за реальные границы картинки
                width: Math.min(
                    Math.floor(Math.abs(areaState.current.width * originalToAreaRatio.current)),
                    originalWidth
                ),
                height: Math.min(
                    Math.floor(Math.abs(areaState.current.height * originalToAreaRatio.current)),
                    originalHeight
                ),
                x: Math.floor(Math.abs(areaState.current.x * originalToAreaRatio.current)),
                y: Math.floor(Math.abs(areaState.current.y * originalToAreaRatio.current)),
            },
            originalToAreaRatio: originalToAreaRatio.current,
        });
    }, [onResizeCallback, originalHeight, originalWidth]);

    const setAreaPosition = useCallback(() => {
        if (!areaElement?.current) {
            return;
        }
        areaElement.current.style.borderLeftWidth = `${areaState.current.x}px`;
        areaElement.current.style.borderTopWidth = `${areaState.current.y}px`;
        areaElement.current.style.borderRightWidth = `${
            containerSize.current.width - areaState.current.width - areaState.current.x
        }px`;
        areaElement.current.style.borderBottomWidth = `${
            containerSize.current.height - areaState.current.height - areaState.current.y
        }px`;
        if (areaMobileFrameElement?.current) {
            areaMobileFrameElement.current.style.width = `${areaState.current.height * MOBILE_FRAME_RATIO}px`;
        }

        handleCallResizeCallback();
    }, [handleCallResizeCallback]);

    /** Считает позиционирование с учетом масштабирования от исходного размера картинки */
    const initRelativeZone = () => {
        if (ratio) {
            areaState.current.width = stateWidth / originalToAreaRatio.current;
            areaState.current.height = stateHeight / originalToAreaRatio.current;

            if (isCentered) {
                areaState.current.x = (containerSize.current.width - stateWidth / originalToAreaRatio.current) / 2;
                areaState.current.y = (containerSize.current.height - stateHeight / originalToAreaRatio.current) / 2;
            } else {
                areaState.current.x = stateX / originalToAreaRatio.current;
                areaState.current.y = stateY / originalToAreaRatio.current;
            }
        } else {
            areaState.current.width = containerSize.current.width * DEFAULT_AREA_RELATIVE_WIDTH;
            areaState.current.height = containerSize.current.height * DEFAULT_AREA_RELATIVE_HEIGHT;
            areaState.current.x = containerSize.current.width * DEFAULT_AREA_RELATIVE_X;
            areaState.current.y = containerSize.current.height * DEFAULT_AREA_RELATIVE_Y;
        }
        setAreaPosition();
    };

    /** определяем пропорции (originalToAreaRatio) оригинал / контейнер */
    const imageOnLoad = () => {
        if (!imageElement?.current || !containerElement?.current) {
            return;
        }
        if (containerMaximumWidth) {
            imageElement.current.style.maxWidth = `${containerMaximumWidth}px`;
        }
        if (containerMaximumHeight) {
            imageElement.current.style.maxHeight = `${containerMaximumHeight}px`;
        }

        containerElement.current.style.width = `${Math.floor(imageElement.current.offsetWidth)}px`;
        containerElement.current.style.height = `${Math.floor(imageElement.current.offsetHeight)}px`;

        containerSize.current.width = imageElement.current.offsetWidth;
        containerSize.current.height = imageElement.current.offsetHeight;

        const imageIsLandscape = originalWidth / originalHeight > 1;
        originalToAreaRatio.current = imageIsLandscape
            ? originalHeight / containerSize.current.height
            : originalWidth / containerSize.current.width;
        initRelativeZone();
    };

    const setXYOnDragStart = (event: React.PointerEvent) => {
        onDragStart?.();
        const { clientX, clientY } = event;
        if (clientX === undefined || clientY === undefined) {
            return;
        }
        dragParams.current.clickX = clientX;
        dragParams.current.clickY = clientY;
        dragParams.current.savedAreaStateX = areaState.current.x;
        dragParams.current.savedAreaStateY = areaState.current.y;
        dragParams.current.savedAreaStateWidth = areaState.current.width;
        dragParams.current.savedAreaStateHeight = areaState.current.height;
    };

    const clearCurrentSelectElement = useCallback(() => {
        onDragStop?.();
        setAreaStateFunction.current = null;
    }, [onDragStop]);
    const leftSideIsOutOfBounds = () => areaState.current.x < 0;
    const rightSideIsOutOfBounds = () => areaState.current.x + areaState.current.width > containerSize.current.width;
    const topSideIsOutOfBounds = () => areaState.current.y < 0;
    const bottomSideIsOutOfBounds = () => areaState.current.y + areaState.current.height > containerSize.current.height;
    const setAreaStateOnAreaDrag: SetAreaStateFunction = useCallback(
        (deltaX, deltaY) => {
            areaState.current.x = dragParams.current.savedAreaStateX + deltaX;
            areaState.current.y = dragParams.current.savedAreaStateY + deltaY;

            if (leftSideIsOutOfBounds()) {
                areaState.current.x = 0;
            }
            if (topSideIsOutOfBounds()) {
                areaState.current.y = 0;
            }
            if (rightSideIsOutOfBounds()) {
                areaState.current.x = containerSize.current.width - areaState.current.width;
            }
            if (bottomSideIsOutOfBounds()) {
                areaState.current.y = containerSize.current.height - areaState.current.height;
            }
        },
        [areaState, containerSize]
    );
    const fitAreaState = {
        onRightBottomCornerDrag: {
            runFit: () => {
                if (rightSideIsOutOfBounds()) {
                    fitAreaState.onRightBottomCornerDrag.onRightSideIsOutOfBounds();
                }
                if (bottomSideIsOutOfBounds()) {
                    fitAreaState.onRightBottomCornerDrag.onBottomSideIsOutOfBounds();
                }
            },
            onRightSideIsOutOfBounds: () => {
                areaState.current.width = containerSize.current.width - areaState.current.x;
                if (ratio) {
                    areaState.current.height = areaState.current.width / ratio;
                }
            },
            onBottomSideIsOutOfBounds: () => {
                areaState.current.height = containerSize.current.height - areaState.current.y;
                if (ratio) {
                    areaState.current.width = areaState.current.height * ratio;
                }
            },
        },
        onLeftBottomCornerDrag: {
            runFit: () => {
                if (leftSideIsOutOfBounds()) {
                    fitAreaState.onLeftBottomCornerDrag.onLeftSideIsOutOfBounds();
                }
                if (bottomSideIsOutOfBounds()) {
                    fitAreaState.onLeftBottomCornerDrag.onBottomSideIsOutOfBounds();
                }
            },
            onLeftSideIsOutOfBounds: () => {
                const savedX = areaState.current.x;
                areaState.current.x = 0;
                areaState.current.width += savedX;
                if (ratio) {
                    areaState.current.height = areaState.current.width / ratio;
                }
            },
            onBottomSideIsOutOfBounds: () => {
                const savedWidth = areaState.current.width;
                areaState.current.height = containerSize.current.height - areaState.current.y;
                if (ratio) {
                    areaState.current.width = areaState.current.height * ratio;
                }
                areaState.current.x -= areaState.current.width - savedWidth;
            },
        },
        onRightTopCornerDrag: {
            runFit: () => {
                if (rightSideIsOutOfBounds()) {
                    fitAreaState.onRightTopCornerDrag.onRightSideIsOutOfBounds();
                }
                if (topSideIsOutOfBounds()) {
                    fitAreaState.onRightTopCornerDrag.onTopSideIsOutOfBounds();
                }
            },
            onRightSideIsOutOfBounds: () => {
                areaState.current.width = containerSize.current.width - areaState.current.x;
                if (ratio) {
                    const savedHeight = areaState.current.height;
                    areaState.current.height = areaState.current.width / ratio;
                    areaState.current.y -= areaState.current.height - savedHeight;
                }
            },
            onTopSideIsOutOfBounds: () => {
                const savedY = areaState.current.y;
                areaState.current.y = 0;
                areaState.current.height += savedY;
                if (ratio) {
                    areaState.current.width = areaState.current.height * ratio;
                }
            },
        },
        onLeftTopCornerDrag: {
            runFit: () => {
                if (leftSideIsOutOfBounds()) {
                    fitAreaState.onLeftTopCornerDrag.onLeftSideIsOutOfBounds();
                }
                if (topSideIsOutOfBounds()) {
                    fitAreaState.onLeftTopCornerDrag.onTopSideIsOutOfBounds();
                }
            },
            onLeftSideIsOutOfBounds: () => {
                const savedX = areaState.current.x;
                const savedHeight = areaState.current.height;
                areaState.current.x = 0;
                areaState.current.width += savedX;
                if (ratio) {
                    areaState.current.height = areaState.current.width / ratio;
                }
                areaState.current.y -= areaState.current.height - savedHeight;
            },
            onTopSideIsOutOfBounds: () => {
                const savedY = areaState.current.y;
                const savedWidth = areaState.current.width;
                areaState.current.y = 0;
                areaState.current.height += savedY;
                if (ratio) {
                    areaState.current.width = areaState.current.height * ratio;
                }
                areaState.current.x -= areaState.current.width - savedWidth;
            },
        },
    };
    const setAreaStateOnRightBottomCornerDrag: SetAreaStateFunction = (deltaX, deltaY) => {
        areaState.current.width = dragParams.current.savedAreaStateWidth + deltaX;
        if (ratio) {
            areaState.current.height = areaState.current.width / ratio;
        } else {
            areaState.current.height = dragParams.current.savedAreaStateHeight + deltaY;
        }
        fitAreaState.onRightBottomCornerDrag.runFit();
    };
    const setAreaStateOnRightTopCornerDrag: SetAreaStateFunction = (deltaX, deltaY) => {
        const savedHeight = areaState.current.height;
        areaState.current.width = dragParams.current.savedAreaStateWidth + deltaX;
        if (ratio) {
            areaState.current.height = areaState.current.width / ratio;
            areaState.current.y -= areaState.current.height - savedHeight;
        } else {
            const savedY = areaState.current.y;
            areaState.current.y = dragParams.current.savedAreaStateY + deltaY;
            areaState.current.height += savedY - areaState.current.y;
        }
        fitAreaState.onRightTopCornerDrag.runFit();
    };
    const setAreaStateOnLeftBottomCornerDrag: SetAreaStateFunction = (deltaX, deltaY) => {
        areaState.current.x = dragParams.current.savedAreaStateX + deltaX;
        areaState.current.width = dragParams.current.savedAreaStateWidth - deltaX;
        if (ratio) {
            areaState.current.height = areaState.current.width / ratio;
        } else {
            areaState.current.height = dragParams.current.savedAreaStateHeight + deltaY;
        }
        fitAreaState.onLeftBottomCornerDrag.runFit();
    };
    const setAreaStateOnLeftTopCornerDrag: SetAreaStateFunction = (deltaX, deltaY) => {
        const savedHeight = areaState.current.height;
        areaState.current.x = dragParams.current.savedAreaStateX + deltaX;
        areaState.current.width = dragParams.current.savedAreaStateWidth - deltaX;
        if (ratio) {
            areaState.current.height = areaState.current.width / ratio;
            areaState.current.y -= areaState.current.height - savedHeight;
        } else {
            const savedY = areaState.current.y;
            areaState.current.y = dragParams.current.savedAreaStateY + deltaY;
            areaState.current.height += savedY - areaState.current.y;
        }
        fitAreaState.onLeftTopCornerDrag.runFit();
    };

    const throttledDrag = useMemo(
        () =>
            throttle((event: PointerEvent) => {
                if (!setAreaStateFunction.current) {
                    return;
                }

                const { clientX, clientY } = event;
                /** сохраняем текущее состояние чтобы можно было вернуть если выделение выйдет за границы контейнера */
                const savedCurrentAreaState = { ...areaState.current };
                const deltaX = clientX - dragParams.current.clickX;
                const deltaY = clientY - dragParams.current.clickY;

                setAreaStateFunction.current(deltaX, deltaY);
                /** дошли до минимальных значений - сбрасываем состояние (только если менялись размеры) */
                if (
                    setAreaStateFunction.current !== setAreaStateOnAreaDrag &&
                    (Math.ceil(areaState.current.width * originalToAreaRatio.current) < minimumWidth ||
                        Math.ceil(areaState.current.height * originalToAreaRatio.current) < minimumHeight)
                ) {
                    areaState.current = { ...savedCurrentAreaState };
                    setAreaPosition();
                    return;
                }

                /** передаем изменившиеся координаты в роидтельский компонент */
                handleCallResizeCallback();

                setAreaPosition();
            }, MOUSEMOVE_THROTTLE_DELAY_MS),
        [setAreaStateOnAreaDrag, minimumWidth, minimumHeight, handleCallResizeCallback, setAreaPosition]
    );

    const setAreaStateFunctionOnStartAreaDrag = (event: React.PointerEvent) => {
        setAreaStateFunction.current = setAreaStateOnAreaDrag;
        setXYOnDragStart(event);
    };

    const draggedPoints = [
        { type: DraggedPointType.LeftTop, setAreaStateFunction: setAreaStateOnLeftTopCornerDrag },
        { type: DraggedPointType.RightTop, setAreaStateFunction: setAreaStateOnRightTopCornerDrag },
        { type: DraggedPointType.LeftBottom, setAreaStateFunction: setAreaStateOnLeftBottomCornerDrag },
        {
            type: DraggedPointType.RightBottom,
            setAreaStateFunction: setAreaStateOnRightBottomCornerDrag,
        },
    ];

    useEffect(() => {
        document.addEventListener('pointerup', clearCurrentSelectElement);
        document.addEventListener('pointermove', throttledDrag);

        return () => {
            document.removeEventListener('pointerup', clearCurrentSelectElement);
            document.removeEventListener('pointermove', throttledDrag);
        };
    }, [clearCurrentSelectElement, throttledDrag]);

    useEffect(() => {
        onResizeCallback?.(DEFAULT_CROP_RESULT);
    }, [onResizeCallback]);

    return (
        <div
            className={styles.imageCrop}
            ref={containerElement}
            onTouchMove={(e) => e.stopPropagation()}
            onTouchStart={(e) => e.stopPropagation()}
        >
            <img ref={imageElement} src={src} onLoad={imageOnLoad} className={styles.imageCropImage} />
            <div className={styles.imageCropArea} ref={areaElement}>
                <div
                    className={classnames(styles.imageCropAreaInner, {
                        [styles.circleMask]: isCircleMask,
                    })}
                >
                    {hasFrameMobile && (
                        <div className={classnames(styles.imageCropAreaMobileFrame)} ref={areaMobileFrameElement} />
                    )}
                    {draggedPoints.map((point) => (
                        <DragablePoint
                            key={point.type}
                            type={point.type}
                            pointType={pointType}
                            onPointerDown={(event) => {
                                setAreaStateFunction.current = point.setAreaStateFunction;
                                setXYOnDragStart(event);
                            }}
                        />
                    ))}
                    <div onPointerDown={setAreaStateFunctionOnStartAreaDrag} className={styles.imageCropAreaDragZone} />
                </div>
            </div>
        </div>
    );
};

export default ImageCrop;
