import { useRef, useCallback } from 'react';

import { AutosuggestWithText } from 'src/utils/suggest/buildDataProvider';

const MIN_CHARS_COUNT = 2;

export const useSelectOnBlur = <T extends AutosuggestWithText>(
    value: string,
    handleSelect: (value: string, data: T | undefined) => boolean,
    minCharsCount: number | undefined = MIN_CHARS_COUNT
): [(value: string, data: T | undefined) => boolean, () => void, (data: T[]) => void] => {
    const firstInListRef = useRef<T | null>(null);

    const select = useCallback(
        (value: string, data: T | undefined) => {
            firstInListRef.current = null;
            return handleSelect(value, data);
        },
        // TODO: баг eslint, https://jira.hh.ru/browse/HH-215026

        [handleSelect]
    );

    const pickFirstValue = () => {
        if (firstInListRef.current && value?.trim().length >= minCharsCount) {
            select(value, firstInListRef.current);
        }
    };

    const onSaveFirst = useCallback((data: T[]) => {
        if (data && data.length > 0) {
            firstInListRef.current = data[0];
        } else {
            firstInListRef.current = null;
        }
        // TODO: баг eslint, https://jira.hh.ru/browse/HH-215026
    }, []);

    return [select, pickFirstValue, onSaveFirst];
};
