import { Children, ReactElement, MutableRefObject, RefObject } from 'react';

const AUTO_SCROLL_OFFSET = 100;
const AUTO_SCROLL_SPEED = 3;

export const getChildrenKeys = (children: ReactElement[] | ReactElement): number[] => {
    return Children.map(children, (child) => {
        return Number(child.key);
    });
};

export type UniversalDragEvent = MouseEvent | TouchEvent;

const isMouseEvent = (event: UniversalDragEvent): event is MouseEvent =>
    ['mousemove', 'mousedown'].includes(event.type);

interface EventPositions {
    clientX: number;
    clientY: number;
    pageX: number;
    pageY: number;
}

const getClientXYOnDragEvents = (event: UniversalDragEvent): EventPositions => {
    let clientX;
    let clientY;
    let pageX;
    let pageY;

    if (isMouseEvent(event)) {
        clientX = event.clientX;
        clientY = event.clientY;
        pageX = event.pageX;
        pageY = event.pageY;
    } else {
        clientX = event.touches[0].clientX;
        clientY = event.touches[0].clientY;
        pageX = event.touches[0].pageX;
        pageY = event.touches[0].pageY;
    }

    return { clientX, clientY, pageX, pageY };
};

const setDragElementPosition = (
    element: HTMLElement,
    eventPositions: EventPositions,
    container: HTMLDivElement,
    offsets: ClickOffset
) => {
    const containerSizes = container.getBoundingClientRect();
    element.style.left = `${eventPositions.clientX - containerSizes.left - offsets.left}px`;
    element.style.top = `${eventPositions.clientY - containerSizes.top - offsets.top}px`;
};

export interface ClickOffset {
    left: number;
    top: number;
}

export type DragElements = HTMLDivElement[];

type SetDropPositionIndex = (newValue: number | null) => void;
type SetMovedUp = (newValue: boolean) => void;
type DragElementsRef = MutableRefObject<DragElements>;
type DraggedRef = MutableRefObject<boolean>;
type ContainerRef = RefObject<HTMLDivElement>;

export type OnMove = (
    event: UniversalDragEvent,
    params: {
        dragged: DraggedRef;
        dragElements: DragElementsRef;
        currentDragIndex: number | null;
        lastMouseEvent: MutableRefObject<UniversalDragEvent | undefined>;
        startPageY: number;
        container: ContainerRef;
        clickOffset: ClickOffset;
        setDropPositionIndex: SetDropPositionIndex;
        setMovedUp: SetMovedUp;
        scrollByContainer?: boolean;
    }
) => void;

export const onMove: OnMove = (
    event,
    {
        dragged,
        dragElements,
        currentDragIndex,
        lastMouseEvent,
        startPageY,
        container,
        clickOffset,
        setDropPositionIndex,
        setMovedUp,
        scrollByContainer,
    }
) => {
    if (!dragged.current || !dragElements.current.length || currentDragIndex === null || !container.current) {
        return;
    }
    lastMouseEvent.current = event;
    const eventPositions = getClientXYOnDragEvents(event);
    const movedUp = eventPositions.pageY < startPageY;
    const currentDragElement = dragElements.current[currentDragIndex];
    setDragElementPosition(currentDragElement, eventPositions, container.current, clickOffset);

    const dragElementBounds = currentDragElement.getBoundingClientRect();

    let collisionElementIndex = null;
    dragElements.current.find((element, index, array) => {
        const currentElementBounds = element?.getBoundingClientRect?.();
        if (!currentElementBounds) {
            return false;
        }
        const prevElementBounds = array[index - 1]?.getBoundingClientRect?.() || { bottom: 0, height: 0 };
        const nextElementBounds = array[index + 1]?.getBoundingClientRect?.() || { top: Infinity, height: 0 };
        const collisionDetected =
            (movedUp &&
                dragElementBounds.top + clickOffset.top < currentElementBounds.top + currentElementBounds.height / 2 &&
                dragElementBounds.top + clickOffset.top > prevElementBounds.bottom - prevElementBounds.height / 2 &&
                array[index - 1] !== currentDragElement) ||
            (!movedUp &&
                dragElementBounds.top + clickOffset.top >
                    currentElementBounds.bottom - currentElementBounds.height / 2 &&
                dragElementBounds.top + clickOffset.top < nextElementBounds.top + nextElementBounds.height / 2 &&
                array[index + 1] !== currentDragElement);

        if (collisionDetected) {
            collisionElementIndex = index;
        }
        return collisionDetected;
    });

    setDropPositionIndex(collisionElementIndex);
    setMovedUp(movedUp);

    let scrollContainer: HTMLElement | Window = window;
    let containerHeight = window.innerHeight;
    let mouseTopOffsetPositionContainer = eventPositions.clientY;

    if (scrollByContainer && container.current.parentElement) {
        scrollContainer = container.current.parentElement;
        const containerBounds = scrollContainer.getBoundingClientRect();
        containerHeight = containerBounds.height;
        mouseTopOffsetPositionContainer = eventPositions.clientY - containerBounds.y;
    }

    if (mouseTopOffsetPositionContainer < AUTO_SCROLL_OFFSET) {
        scrollContainer.scrollBy(0, -AUTO_SCROLL_SPEED);
    } else if (
        mouseTopOffsetPositionContainer < containerHeight &&
        mouseTopOffsetPositionContainer > containerHeight - AUTO_SCROLL_OFFSET
    ) {
        scrollContainer.scrollBy(0, AUTO_SCROLL_SPEED);
    }
};

type SetCurrentDragIndex = (newValue: number | null) => void;
type OnDragStart = (
    event: UniversalDragEvent,
    params: {
        index: number;
        dragged: DraggedRef;
        dragElements: DragElementsRef;
        container: ContainerRef;
        setDropPositionIndex: SetDropPositionIndex;
        setClickOffset: (newValue: ClickOffset) => void;
        setCurrentDragIndex: SetCurrentDragIndex;
        setStartPageY: (newValue: number) => void;
    }
) => void;

export const onDragStart: OnDragStart = (
    event,
    {
        index,
        dragged,
        dragElements,
        container,
        setDropPositionIndex,
        setClickOffset,
        setCurrentDragIndex,
        setStartPageY,
    }
) => {
    if (!container.current) {
        return;
    }
    const eventPositions = getClientXYOnDragEvents(event);
    dragged.current = true;
    const currentDragElement = dragElements.current[index];
    const dragElementBounds = currentDragElement.getBoundingClientRect();
    const offsets = {
        left: eventPositions.clientX - dragElementBounds.left,
        top: eventPositions.clientY - dragElementBounds.top,
    };
    setDragElementPosition(currentDragElement, eventPositions, container.current, offsets);
    setDropPositionIndex(index);
    setClickOffset(offsets);
    setCurrentDragIndex(index);
    setStartPageY(eventPositions.pageY);
};

export type OnDrop = (from: number, to: number) => void;
type OnDragEnd = (params: {
    dragged: DraggedRef;
    currentDragIndex: number | null;
    dropPositionIndex: number | null;
    onDrop: OnDrop;
    setDropPositionIndex: SetDropPositionIndex;
    setCurrentDragIndex: SetCurrentDragIndex;
}) => void;

export const onDragEnd: OnDragEnd = ({
    dragged,
    currentDragIndex,
    dropPositionIndex,
    onDrop,
    setDropPositionIndex,
    setCurrentDragIndex,
}) => {
    dragged.current = false;
    if (currentDragIndex !== null && dropPositionIndex !== null) {
        onDrop(currentDragIndex, dropPositionIndex);
    }
    setDropPositionIndex(null);
    setCurrentDragIndex(null);
};
