import { FC, PropsWithChildren, useEffect } from 'react';

import { EmployerPageTab, usePageTabsContext } from 'src/components/Employer/PageTabs/PageTabsContext';

import styles from './tab-content.less';

interface EmployerPageTabContentProps {
    tab: EmployerPageTab;
}

const EmployerPageTabContent: FC<PropsWithChildren<EmployerPageTabContentProps>> = ({ tab, children }) => {
    const { showContentForTab, currentTab, setCurrentTab } = usePageTabsContext();

    useEffect(() => {
        if (currentTab === EmployerPageTab.Description) {
            return undefined;
        }
        // Если находимся на табе с вакансиями, то при клике на виджет DJ необходимо перенаправлять на таб
        // с описанием и проскроливать к отзывам
        const handler = () => {
            setCurrentTab(EmployerPageTab.Description);
            setTimeout(() => window.dispatchEvent(new CustomEvent('HH-Dream-Job-Scroll-To-Big-Widget')), 0);
        };
        window.addEventListener('HH-Dream-Job-Scroll-To-Big-Widget', handler);
        return () => window.removeEventListener('HH-Dream-Job-Scroll-To-Big-Widget', handler);
    }, [currentTab, setCurrentTab]);

    if (!showContentForTab(tab)) {
        return <div className={styles.hidden}>{children}</div>;
    }

    return <>{children}</>;
};

export default EmployerPageTabContent;
