import { useEffect, useRef, FC, PropsWithChildren } from 'react';
import classnames from 'classnames';

import { Skeleton } from '@hh.ru/magritte-ui';
import { H2 } from 'bloko/blocks/header';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import ScrollToElement from 'Utils/ScrollToElement';
import ContainerForMicroFrontend from 'src/components/CodeInjector/ContainerForMicroFrontend';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

import checkWidgetRender from 'src/components/EmployerReviews/WidgetBig/checkRender';

import styles from './widgetBig.less';

const TrlKeys = {
    title: 'employerReviews.bigWidget.title',
};

const WidgetWrapper: FC<{ isRedesign?: boolean; hasWrapper?: boolean } & PropsWithChildren> = ({
    isRedesign,
    hasWrapper,
    children,
}) =>
    hasWrapper ? (
        <div className={classnames({ [styles.wrapper]: hasWrapper, [styles.wrapperRedesigned]: isRedesign })}>
            {children}
        </div>
    ) : (
        <>{children}</>
    );

interface EmployerReviewWidgetBigProps {
    isRedesign?: boolean;
    hasWrapper?: boolean;
}

const EmployerReviewsBigWidget: TranslatedComponent<EmployerReviewWidgetBigProps> = ({
    isRedesign,
    hasWrapper = true,
    trls,
}) => {
    const widgetElement = useRef<HTMLDivElement>(null);
    const showWidget = useSelector(checkWidgetRender);

    useEffect(() => {
        const handler = () => ScrollToElement(widgetElement.current);
        window.addEventListener('HH-Dream-Job-Scroll-To-Big-Widget', handler);
        return () => window.removeEventListener('HH-Dream-Job-Scroll-To-Big-Widget', handler);
    }, []);

    if (!showWidget) {
        return null;
    }

    return (
        <div ref={widgetElement} className="noprint">
            <WidgetWrapper isRedesign={isRedesign} hasWrapper={hasWrapper}>
                {isRedesign ? (
                    <>
                        {hasWrapper && <VSpacing base={12} xs={1} />}
                        <H2>{trls[TrlKeys.title]}</H2>
                        <VSpacing base={6} xs={3} />
                    </>
                ) : (
                    <>
                        <VSpacing base={7} />
                        <H2>{trls[TrlKeys.title]}</H2>
                        <VSpacing base={3} />
                    </>
                )}
                <ContainerForMicroFrontend
                    place="employerReviewsBig"
                    preloadContent={<Skeleton height={164} loading borderRadius={16} />}
                />
            </WidgetWrapper>
        </div>
    );
};

export default translation(EmployerReviewsBigWidget);
