import { forwardRef, ReactElement } from 'react';

import { Avatar, Cell, CellText } from '@hh.ru/magritte-ui';
import { Link } from '@hh.ru/redux-spa-middleware';

import { useSelector } from 'src/hooks/useSelector';

import styles from './item.less';

export interface ItemProps {
    icon: ReactElement;
    title: string | string[];
    subtitle: string;
    style?: 'secondary' | 'accent';
    titleAsLink?: boolean;
    onClick?: VoidFunction;
}

const Item = forwardRef<HTMLDivElement, ItemProps>(
    ({ icon, title, subtitle, style = 'secondary', titleAsLink = false, onClick }, ref) => {
        const hasChameleon = useSelector(({ hasChameleon }) => hasChameleon);

        return (
            <div ref={ref} className={hasChameleon ? styles.chameleonItem : undefined} onClick={onClick}>
                <Cell left={<Avatar shapeCircle style={style} mode="icon" aria-label="city" size={48} icon={icon} />}>
                    <CellText maxLines={1}>
                        {titleAsLink && !Array.isArray(title) ? (
                            <Link to={title} target="_blank">
                                {title}
                            </Link>
                        ) : (
                            title
                        )}
                    </CellText>
                    <CellText type="subtitle">{subtitle}</CellText>
                </Cell>
            </div>
        );
    }
);

export default Item;
