import { useRef } from 'react';
import { GetInputPropsFn } from 'react-dropzone';

import { CameraOutlinedSize16, PenSquareOutlinedSize16, TrashOutlinedSize16 } from '@hh.ru/magritte-ui/icon';
import Button, { ButtonIconPosition } from 'bloko/blocks/button';
import HoverTip from 'bloko/blocks/drop/Tip/HoverTip';
import Loading, { LoadingScale, LoadingColor } from 'bloko/blocks/loading';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import BlokoIconReplaceContainer from 'src/components/BlokoIconReplaceContainer';
import { MixedEvent } from 'src/components/EmployerConstructor/getFileFromEvent';
import translation from 'src/components/translation';
import { Status } from 'src/models/employerConstructor';

import styles from './employer-constructor-header.less';

const TrlKeys = {
    uploadAnother: 'employer.constructor.header.uploadAnother',
    editTooltip: 'employer.constructor.header.edit.tooltip',
};

interface HeaderEditControlsProps {
    allowedMimeTypes?: string;
    resizeStatus?: string;
    onLoad?: (event: MixedEvent) => void;
    getInputProps: GetInputPropsFn;
    enhancedToggleCropPopupVisible?: () => void;
    removeHeaderImage?: () => void;
}

const HeaderEditControls: TranslatedComponent<HeaderEditControlsProps> = ({
    trls,
    getInputProps,
    resizeStatus,
    allowedMimeTypes,
    onLoad,
    enhancedToggleCropPopupVisible,
    removeHeaderImage,
}) => {
    const activatorRef = useRef(null);
    return (
        <div className={styles.employerConstructorHeaderControls}>
            <label>
                <Button
                    Element="span"
                    icon={
                        <BlokoIconReplaceContainer>
                            <CameraOutlinedSize16 />
                        </BlokoIconReplaceContainer>
                    }
                    loading={
                        resizeStatus === Status.Fetching && (
                            <Loading initial={LoadingColor.Gray80} scale={LoadingScale.Small} />
                        )
                    }
                    iconPosition={ButtonIconPosition.Left}
                >
                    {trls[TrlKeys.uploadAnother]}
                    <input
                        {...getInputProps()}
                        type="file"
                        className="g-hidden"
                        accept={allowedMimeTypes}
                        onChange={onLoad}
                        data-qa="constructor-head__upload-image-input"
                    />
                </Button>
            </label>
            <div className={styles.employerConstructorHeaderButtonWrapper}>
                <HoverTip
                    host={!process.env.SSR ? document.body : null}
                    render={() => trls[TrlKeys.editTooltip]}
                    activatorRef={activatorRef}
                >
                    <Button
                        onClick={enhancedToggleCropPopupVisible}
                        icon={
                            <BlokoIconReplaceContainer>
                                <PenSquareOutlinedSize16 />
                            </BlokoIconReplaceContainer>
                        }
                        data-qa="constructor-head__edit-image-button"
                        innerRef={activatorRef}
                    />
                </HoverTip>
            </div>
            <div className={styles.employerConstructorHeaderButtonWrapper}>
                <Button
                    onClick={removeHeaderImage}
                    icon={
                        <BlokoIconReplaceContainer>
                            <TrashOutlinedSize16 />
                        </BlokoIconReplaceContainer>
                    }
                    data-qa="constructor-head__remove-image-button"
                />
            </div>
        </div>
    );
};

export default translation(HeaderEditControls);
