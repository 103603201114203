import { Layer, LayerName, VSpacing } from '@hh.ru/magritte-ui';
import Column, { ColumnsWrapper } from 'bloko/blocks/column';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import EmployerPageTabs from 'src/components/Employer/PageTabs/EmployerPageTabs';
import translation from 'src/components/translation';

import Actions from 'src/components/Employer/EmployerView/Header/Actions';
import Description from 'src/components/Employer/EmployerView/Header/Description';
import HeaderWrapper from 'src/components/Employer/EmployerView/Header/HeaderWrapper';
import Logo from 'src/components/Employer/EmployerView/Header/Logo';
import TopImage from 'src/components/Employer/EmployerView/Header/TopImage';

import styles from './header.less';

const Header: TranslatedComponent = () => {
    return (
        <>
            <TopImage />
            <HeaderWrapper>
                <ColumnsWrapper>
                    <Column xs="4" s="8" m="12" l="16">
                        <VSpacing default={40} />
                        <div className={styles.content}>
                            <div className={styles.description}>
                                <Logo />
                                <Description />
                            </div>
                            <Actions />
                        </div>
                    </Column>
                </ColumnsWrapper>
            </HeaderWrapper>
            <VSpacing default={16} />
            <Layer layer={LayerName.Alpha}>
                <div className={styles.employerTabsWrapper}>
                    <ColumnsWrapper>
                        <Column xs="4" s="8" m="12" l="16">
                            <VSpacing default={24} />
                            <EmployerPageTabs />
                        </Column>
                    </ColumnsWrapper>
                    <VSpacing default={24} />
                </div>
            </Layer>
        </>
    );
};

export default translation(Header);
