import PropTypes from 'prop-types';

export const EDITOR_TOOLBAR_SIMPLE = 'simple';
export const EDITOR_TOOLBAR_CONSTRUCTOR = 'constructor';
export const EDITOR_TOOLBAR_DEFAULT = 'default';
export const EDITOR_TOOLBAR_EMPLOYER_TESTS = 'employer_tests';

export const EDITOR_TOOLBAR_SIMPLE_NO_ITALIC = 'simple_no_italic';
export const EDITOR_TOOLBAR_CONSTRUCTOR_NO_ITALIC = 'constructor_no_italic';
export const EDITOR_TOOLBAR_DEFAULT_NO_ITALIC = 'default_no_italic';
export const EDITOR_TOOLBAR_EMPLOYER_TESTS_NO_ITALIC = 'employer_tests_no_italic';

const DEFAULT_LANG = 'ru';
const DOCTYPE_STRING =
    '<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.1//EN" "http://www.w3.org/TR/xhtml11/DTD/xhtml11.dtd">';

// скопировано из исходников ckeditor.js
const CKEDITOR_CTRL = 1114112;

const toolbarDefault = [
    ['Source', '-', 'Undo', 'Redo'],
    ['Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord'],
    ['Find', 'Replace', '-', 'SelectAll', 'RemoveFormat'],
    '/',
    ['Format', 'Bold', 'Italic', 'Underline', 'Strike'],
    ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent'],
    ['HorizontalRule'],
    ['About'],
];
const toolbarSimple = [['Format', 'Bold', 'Italic', 'BulletedList', 'NumberedList', 'Undo']];

const toolbarConstructor = [
    ['Undo', 'Redo'],
    ['Format'],
    ['Bold', 'Italic', 'Underline'],
    ['NumberedList', 'BulletedList'],
];

const italicFilter = (panel, group) => [
    ...panel,
    Array.isArray(group) ? group.filter((plugin) => plugin !== 'Italic') : group,
];

const CUSTOM_TOOLBARS = {
    toolbar_default: toolbarDefault,
    toolbar_simple: toolbarSimple,
    toolbar_employer_tests: toolbarDefault,
    toolbar_constructor: toolbarConstructor,

    toolbar_default_no_italic: toolbarDefault.reduce(italicFilter, []),
    toolbar_simple_no_italic: toolbarSimple.reduce(italicFilter, []),
    toolbar_employer_tests_no_italic: toolbarDefault.reduce(italicFilter, []),
    toolbar_constructor_no_italic: toolbarConstructor.reduce(italicFilter, []),
};

export const PLUGINS = {
    AUTO_GROW: 'autogrow',
    RESIZE: 'resize',
    INDENT_BLOCK: 'indentblock',
    ELEMENTS_PATH: 'elementspath',
    MAGIC_LINE: 'magicline',
    SPELL_CHECK_AS_YOU_TYPE: 'scayt',
    LIST_STYLE: 'liststyle',
    TABLE_TOOLS: 'tabletools',
    CONTEXT_MENU: 'contextmenu',
    HIGHLIGHTER: 'highlighter',
};

export const PLUGINS_ACTION = {
    REMOVE: 'remove',
    ADD: 'add',
};

export const DISABLED_PLUGINS = [
    PLUGINS.ELEMENTS_PATH,
    PLUGINS.MAGIC_LINE,
    PLUGINS.SPELL_CHECK_AS_YOU_TYPE,
    PLUGINS.LIST_STYLE,
    PLUGINS.TABLE_TOOLS,
    PLUGINS.CONTEXT_MENU,
    PLUGINS.AUTO_GROW,
];

export const AUTO_GROW = {
    MIN_HEIGHT_PROPERTY: 'autoGrow_minHeight',
    MAX_HEIGHT_PROPERTY: 'autoGrow_maxHeight',
    BOTTOM_SPACE_PROPERTY: 'autoGrow_bottomSpace',
};

export const HIGHLIGHTER = {
    UNDERLINE_COLOR: 'highlighter_underlineColor',
    UNDERLINE_TEXT_DECORATION_COLOR: 'highlighter_underlineTextDecorationColor',
    UNDERLINE_HOVER_COLOR: 'highlighter_underlineHoverColor',
    UNDERLINE_HOVER_BG_COLOR: 'highlighter_underlineHoverBgColor',
    UNDERLINE_ABORT_TIMEOUT_MS: 'highlighter_abortTimeoutMs',
};

export const PropTypeDynamicPlugins = PropTypes.oneOf(Object.values(PLUGINS));

export default {
    language: DEFAULT_LANG,
    resize_enabled: false,
    blockedKeystrokes: [
        CKEDITOR_CTRL + 66 /* B */,
        CKEDITOR_CTRL + 73 /* I */,
        CKEDITOR_CTRL + 85 /* U */,
        CKEDITOR_CTRL + 68 /* D */,
    ],
    // Без включенного плагина (undo, redo и тд) сочетание клавиш ничего не делает
    keystrokes: [
        [CKEDITOR_CTRL + 90, 'undo'] /* Z */,
        [CKEDITOR_CTRL + 89, 'redo'] /* Y */,
        [CKEDITOR_CTRL + 76, 'link'] /* L */,
        [CKEDITOR_CTRL + 66, 'bold'] /* B */,
        [CKEDITOR_CTRL + 73, 'italic'] /* I */,
        [CKEDITOR_CTRL + 85, 'underline'] /* U */,
        [CKEDITOR_CTRL + 68, 'bulletedlist'] /* D */,
    ],
    ...CUSTOM_TOOLBARS,
    format_tags: 'h2;div',
    fillEmptyBlocks: true,
    autoParagraph: false,
    browserContextMenuOnCtrl: true,
    scayt_autoStartup: false,
    disableNativeSpellChecker: false,
    docType: DOCTYPE_STRING,
    filebrowserBrowseUrl: '',
    filebrowserWindowWidth: '80%',
    allowedContent: false,
};
