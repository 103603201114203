import { FC } from 'react';

import { Layer, LayerName } from '@hh.ru/magritte-ui';

import { usePageTabsContext, EmployerPageTab } from 'src/components/Employer/PageTabs/PageTabsContext';
import MagritteWrapper from 'src/components/MagritteWrapper/MagritteWrapper';
import EmployerVacancySearchScrollToTop from 'src/components/SearchScrollToTop/EmployerVacancy';
import { useSelector } from 'src/hooks/useSelector';
import useIsEmployerVacancySearch from 'src/models/employerVacancySearch/useIsEmployerVacancySearch';

import styles from './search-footer-button.less';

const SearchFooterButton: FC = () => {
    const hasChameleon = useSelector(({ hasChameleon }) => hasChameleon);
    const totalResults = useSelector((state) => state.employerVacancySearch.resultsFound ?? 0);
    const { currentTab } = usePageTabsContext();
    const isEmployerVacancySearch = useIsEmployerVacancySearch();

    if (
        !isEmployerVacancySearch ||
        (!(currentTab === EmployerPageTab.Vacancies && totalResults > 3) && !hasChameleon)
    ) {
        return null;
    }

    return (
        <Layer layer={LayerName.Alpha}>
            <div className={styles.searchFooterButton}>
                <MagritteWrapper isEnabled>
                    <EmployerVacancySearchScrollToTop />
                </MagritteWrapper>
            </div>
        </Layer>
    );
};

export default SearchFooterButton;
