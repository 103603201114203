import { FC, PropsWithChildren } from 'react';

import { translation } from '@hh.ru/front-static-app';
import { Card, VSpacingContainer } from '@hh.ru/magritte-ui';
import { BriefcaseOutlinedSize24, DocumentOutlinedSize24, MapPinOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { useSelector } from 'src/hooks/useSelector';

import Item from 'src/components/Employer/EmployerView/RightPanel/Item';

import styles from './right-panel.less';

const TrlKeys = {
    city: 'employerView.rightPanel.companyInfo.city',
    industries: 'employerView.rightPanel.companyInfo.industries',
    category: 'employerView.rightPanel.companyInfo.category',
};

const Wrapper: FC<PropsWithChildren> = ({ children }) => <VSpacingContainer default={24}>{children}</VSpacingContainer>;
const ChameleonWrapper: FC<PropsWithChildren> = ({ children }) => <div className={styles.items}>{children}</div>;

const CompanyInfo: TranslatedComponent = ({ trls }) => {
    const address = useSelector((state) => state.employerInfo.address);
    const employerCategories = useSelector((state) => state.employerCategories);
    const category = useSelector((state) => state.employerInfo.category);
    const industries = useSelector((state) => state.employerInfo.industries);
    const hasChameleon = useSelector(({ hasChameleon }) => hasChameleon);
    const ComponentWrapper = hasChameleon ? ChameleonWrapper : Wrapper;

    const currentCategory = employerCategories.find((item) => item.code.toLowerCase() === category);
    const hasIndustries = !!industries?.length;

    if (!(address || hasIndustries || currentCategory)) {
        return null;
    }

    return (
        <Card padding={24} showBorder borderRadius={24} stretched>
            <ComponentWrapper>
                {address && <Item icon={<MapPinOutlinedSize24 />} title={address} subtitle={trls[TrlKeys.city]} />}
                {hasIndustries && (
                    <Item
                        icon={<BriefcaseOutlinedSize24 />}
                        title={industries.map((industry) => industry.trl).join(', ')}
                        subtitle={trls[TrlKeys.industries]}
                    />
                )}
                {currentCategory && (
                    <Item
                        icon={<DocumentOutlinedSize24 />}
                        title={currentCategory.translation}
                        subtitle={trls[TrlKeys.category]}
                    />
                )}
            </ComponentWrapper>
        </Card>
    );
};

export default translation(CompanyInfo);
