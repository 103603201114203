import { DefaultRootState } from 'react-redux';
import { AxiosError } from 'axios';
import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';

import employerConstructorUploadPictureFormSubmit from '@hh.ru/analytics-js-events/build/xhh/employer/branding/employer_page/employer_constructor_upload_picture_form_submit';

import defaultRequestErrorHandler from 'src/api/notifications/defaultRequestErrorHandler';
import {
    employerConstructorEditForbiddenError,
    employerConstructorInvalidPictureSize,
    employerConstructorPictureFileTooLarge,
    employerConstructorPictureTooLarge,
    employerConstructorUnsupportedPictureFileFormat,
} from 'src/components/Notifications/EmployerConstructor';
import { AddNotification } from 'src/components/Notifications/Provider/types';
import {
    employerConstructorAddImage,
    EmployerConstructorImage,
    Status,
    switchImagesActionWrapper,
} from 'src/models/employerConstructor';
import { PictureType } from 'src/models/employerConstructor/settings';
import fetcher from 'src/utils/fetcher';

import checkResizeStatus, { CHECK_TIMEOUT_MS } from 'src/components/EmployerConstructor/checkResizeStatus';
import getErrorType, { EmployerConstructorError } from 'src/components/EmployerConstructor/getErrorType';

const UPLOAD_IMAGE_URL = '/employer/constructor/upload_image';

declare global {
    interface FetcherPostApi {
        [UPLOAD_IMAGE_URL]: {
            queryParams: void;
            body: {
                file: File;
                pictureType: PictureType;
            };
            response: EmployerConstructorImage;
        };
    }
}

type AxiosErrorWithKey = AxiosError<{ error: { key: string }[] }>;

interface UploadImageParams {
    file: File;
    pictureType?: PictureType;
    widgetId?: number;
    isDragNDrop?: boolean;
    hasAlreadyImage?: boolean;
}

type UploadImage = (
    params: UploadImageParams,
    addNotification: AddNotification
) => ThunkAction<Promise<{ pictureId: number }>, DefaultRootState, unknown, AnyAction>;

const pictureTypeToAnalyticsTypeMap = {
    [PictureType.EmployerPageBackground]: 'constructor_header',
    [PictureType.GalleryWidget]: 'constructor_widget_gallery',
    [PictureType.PictureWidget]: 'constructor_widget_picture',
} as const;

const uploadImage: UploadImage =
    ({ file, pictureType, widgetId, isDragNDrop, hasAlreadyImage }, addNotification) =>
    (dispatch, getState) => {
        return new Promise((resolve, reject) => {
            if (!pictureType) {
                return;
            }
            const imagesConfig = getState().employerConstructorSettings.pictureSettings[pictureType];
            const employerId = getState().employerInfo.id;
            const hasConstructorService = getState().employerConstructor.hasService;

            const formSubmitAnalyticsData = {
                pictureType: pictureTypeToAnalyticsTypeMap[pictureType],
                employerId: employerId || 0,
                employerPageConstructorIsActive: hasConstructorService,
                actionType: hasAlreadyImage ? 'edit' : 'create',
                loadType: isDragNDrop ? 'drag-n-drop' : 'button',
                filename: file.name,
                fileSizeKb: file.size / 1024,
                fileType: file.type,
                fileHeight: 0,
                fileWidth: 0,
            } as const;

            if (!imagesConfig) {
                throw new Error(`Error getting picture settings for ${pictureType}`);
            }
            if (!imagesConfig.allowedMimeTypes.includes(file.type)) {
                addNotification(employerConstructorUnsupportedPictureFileFormat);
                employerConstructorUploadPictureFormSubmit({
                    ...formSubmitAnalyticsData,
                    errors: 'EMPLOYER_CONSTRUCTOR_UNSUPPORTED_PICTURE_FILE_FORMAT',
                });
                reject(new Error('Employer constructor unsupported picture file format'));
                return;
            }
            if (file.size > imagesConfig.maxSizeBytes) {
                addNotification(employerConstructorPictureFileTooLarge);
                employerConstructorUploadPictureFormSubmit({
                    ...formSubmitAnalyticsData,
                    errors: 'EMPLOYER_CONSTRUCTOR_PICTURE_FILE_TOO_LARGE',
                });
                reject(new Error('Employer constructor picture file too large'));
                return;
            }
            dispatch(
                switchImagesActionWrapper({
                    payload: {
                        resizeStatus: Status.Fetching,
                    },
                    widgetId,
                })
            );
            fetcher
                .postFormData(UPLOAD_IMAGE_URL, {
                    file,
                    pictureType,
                })
                .then(
                    ({ data }) => {
                        dispatch(employerConstructorAddImage(data));
                        setTimeout(() => {
                            return dispatch(
                                checkResizeStatus(
                                    {
                                        pictureId: data.pictureId,
                                        widgetId,
                                    },
                                    {
                                        fromUpload: true,
                                    },
                                    addNotification
                                )
                            )
                                .then(() => {
                                    employerConstructorUploadPictureFormSubmit({
                                        ...formSubmitAnalyticsData,
                                        fileWidth: data.originalWidth,
                                        fileHeight: data.originalHeight,
                                    });
                                    resolve({
                                        pictureId: data.pictureId,
                                    });
                                })
                                .catch(() => {
                                    employerConstructorUploadPictureFormSubmit({
                                        ...formSubmitAnalyticsData,
                                        errors: 'FAILED_TO_CHECK_RESIZE_STATUS',
                                    });
                                    reject(new Error('Failed to check resize status'));
                                });
                        }, CHECK_TIMEOUT_MS);
                    },
                    (error: AxiosErrorWithKey) => {
                        dispatch(switchImagesActionWrapper({ payload: { resizeStatus: Status.Fail }, widgetId }));
                        const errorType = getErrorType(error);
                        switch (errorType) {
                            case EmployerConstructorError.EditNotPermitted:
                                addNotification(employerConstructorEditForbiddenError);
                                break;
                            case EmployerConstructorError.PictureFileTooLarge:
                                addNotification(employerConstructorPictureFileTooLarge);
                                break;
                            case EmployerConstructorError.PictureTooLarge:
                                addNotification(employerConstructorPictureTooLarge, {
                                    props: {
                                        maxWidth: imagesConfig.maxWidth,
                                        maxHeight: imagesConfig.maxHeight,
                                    },
                                });
                                break;
                            case EmployerConstructorError.BadPictureFile:
                            case EmployerConstructorError.UnsupportedPictureFileFormat:
                                addNotification(employerConstructorUnsupportedPictureFileFormat);
                                break;
                            case EmployerConstructorError.InvalidPictureSize:
                                addNotification(employerConstructorInvalidPictureSize, {
                                    props: {
                                        minimumWidth: imagesConfig.minWidth,
                                        minimumHeight: imagesConfig.minHeight,
                                    },
                                });
                                break;
                            default:
                                defaultRequestErrorHandler(error, addNotification);
                        }

                        if (errorType) {
                            employerConstructorUploadPictureFormSubmit({
                                ...formSubmitAnalyticsData,
                                errors: errorType,
                            });
                        }
                        reject(new Error('Failed to upload image'));
                    }
                );
        });
    };
export default uploadImage;
