import { useState } from 'react';

import { useBreakpoint } from '@hh.ru/magritte-ui';

import { ImageCropProps } from 'src/components/ImageCropPopup/ImageCrop';

enum Axis {
    X = 'X',
    Y = 'Y',
}

const useImageCropSettings = (): {
    handleSetDimensions: (width: number, height: number) => void;
    imageCropSettings: Omit<ImageCropProps, 'src'>;
} => {
    const [dimensions, setDimensions] = useState({ width: 0, height: 0, smallestSide: 0, smallestSideAxis: Axis.Y });
    const { isMobile } = useBreakpoint();

    const handleSetDimensions = (width: number, height: number) => {
        const smallestSide = width > height ? height : width;
        const smallestSideAxis = width > height ? Axis.Y : Axis.X;

        setDimensions({
            width,
            height,
            smallestSide,
            smallestSideAxis,
        });
    };

    const imageCropSettings = {
        stateX: dimensions.smallestSideAxis === Axis.X ? 0 : (dimensions.width - dimensions.smallestSide) / 2,
        stateY: dimensions.smallestSideAxis === Axis.Y ? 0 : (dimensions.height - dimensions.smallestSide) / 2,
        stateWidth: dimensions.smallestSide,
        stateHeight: dimensions.smallestSide,
        originalWidth: dimensions.width,
        originalHeight: dimensions.height,
        containerMaximumHeight: isMobile ? undefined : 340,
        ratio: 1,
        minimumWidth: 200,
        minimumHeight: 200,
        isCircleMask: true,
        isCentered: true,
    };

    return {
        handleSetDimensions,
        imageCropSettings,
    };
};

export default useImageCropSettings;
