import { FC, memo } from 'react';

import { useBreakpoint } from '@hh.ru/magritte-ui';

import DesktopActions from 'src/components/Employer/EmployerView/Header/Actions/DesktopActions';
import MobileActions from 'src/components/Employer/EmployerView/Header/Actions/MobileActions';

const Actions: FC = () => {
    const { isMobile } = useBreakpoint();

    return isMobile ? <MobileActions /> : <DesktopActions />;
};

export default memo(Actions);
