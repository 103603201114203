import { FC } from 'react';

import { Avatar } from '@hh.ru/magritte-ui';

import CdnImg from 'src/components/CdnImg';
import { useSelector } from 'src/hooks/useSelector';

import styles from './logo.less';

const Logo: FC = () => {
    const logoUrl = useSelector((state) => state.employerLogo.logoUrl);
    const companyName = useSelector((state) => state.employerInfo.name);

    if (!companyName) {
        return null;
    }

    if (!logoUrl) {
        // TODO: После эксперимента появится размер в magritte, поменять
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return <Avatar mode="placeholder" size={80} placeholder="city" shapeCircle aria-label={companyName} />;
    }

    return (
        <CdnImg loading="lazy" className={styles.root} path={logoUrl} alt={companyName} data-qa="company-logo-image" />
    );
};

export default Logo;
