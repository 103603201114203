import { FC } from 'react';

import { Alert, Button } from '@hh.ru/magritte-ui';
import { ExclamationTriangleFilledSize24 } from '@hh.ru/magritte-ui/icon';

interface ImageDeleteModalProps {
    onModalClose: () => void;
    primaryAction: () => void;
    visible: boolean;
    title: string;
    primaryActionTitle: string;
    cancel: string;
}

const ImageAlertModal: FC<ImageDeleteModalProps> = ({
    onModalClose,
    primaryAction,
    visible,
    title,
    primaryActionTitle,
    cancel,
}) => {
    return (
        <Alert
            title={title}
            icon={<ExclamationTriangleFilledSize24 />}
            iconStyle={'warning'}
            layout="horizontal"
            visible={visible}
            buttons={
                <>
                    <Button mode="primary" style={'accent'} size="medium" stretched onClick={primaryAction}>
                        {primaryActionTitle}
                    </Button>
                    <Button mode="secondary" style="neutral" size="medium" stretched onClick={onModalClose}>
                        {cancel}
                    </Button>
                </>
            }
            onClose={onModalClose}
        />
    );
};

export default ImageAlertModal;
