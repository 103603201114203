import { useCallback, useState, useRef, useEffect } from 'react';
import Dropzone, { DropzoneRenderFunction, GetInputPropsFn } from 'react-dropzone';
import { useDispatch } from 'react-redux';

import { ElementShownAnchor } from '@hh.ru/analytics-js';
import employerUploadLogoFormSubmit from '@hh.ru/analytics-js-events/build/xhh/employer/branding/employer_page/employer_upload_logo_form_submit';
import sidebarButtonClick from '@hh.ru/analytics-js-events/build/xhh/employer/branding/employer_page/sidebar_button_click';
import sidebarElementShown from '@hh.ru/analytics-js-events/build/xhh/employer/branding/employer_page/sidebar_element_shown';
import { Layer, LayerName } from '@hh.ru/magritte-ui';
import { CameraOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { useSelectorNonNullable } from '@hh.ru/redux-create-reducer';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import deleteLogo from 'src/components/Employer/Sidebar/deleteLogo';
import uploadLogo, { AnalyticsParams } from 'src/components/Employer/Sidebar/uploadLogo';
import { CropResult } from 'src/components/ImageCropPopup/ImageCrop';
import {
    employerLogoFileTooLarge,
    employerLogoUnsupportedFileFormat,
    employerLogoFileSizeTooSmall,
} from 'src/components/Notifications/EmployerSidebar';
import Snackbar from 'src/components/Notifications/Notification';
import { useNotification } from 'src/components/Notifications/Provider';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { Status } from 'src/models/employer/employerLogo';

import ImageAlertModal from 'src/components/Employer/Sidebar/CircleLogoEdit/ImageAlertModal';
import ImageCropAdviceModal from 'src/components/Employer/Sidebar/CircleLogoEdit/ImageCropAdviceModal';
import ImageCropCommunicationModal from 'src/components/Employer/Sidebar/CircleLogoEdit/ImageCropCommunicationModal';
import ImageCropModal from 'src/components/Employer/Sidebar/CircleLogoEdit/ImageCropModal';
import ImageViewModal from 'src/components/Employer/Sidebar/CircleLogoEdit/ImageViewModal';
import useImageCropProcess from 'src/components/Employer/Sidebar/CircleLogoEdit/hooks/useImageCropProcess';
import useImageCropSettings from 'src/components/Employer/Sidebar/CircleLogoEdit/hooks/useImageCropSettings';
import useModalManagement, {
    ModalType,
    AlertType,
} from 'src/components/Employer/Sidebar/CircleLogoEdit/hooks/useModalManagement';

import styles from './styles.less';

const TrlKeys = {
    title: {
        [AlertType.Remove]: 'employer.logoEdit.alert.remove.title',
        [AlertType.Exit]: 'employer.logoEdit.alert.exit.title',
        [AlertType.Back]: 'employer.logoEdit.alert.exit.title',
    },
    cancel: {
        [AlertType.Remove]: 'employer.logoEdit.alert.remove.cancel',
        [AlertType.Exit]: 'employer.logoEdit.alert.exit.cancel',
        [AlertType.Back]: 'employer.logoEdit.alert.exit.cancel',
    },
    primaryAction: {
        [AlertType.Remove]: 'employer.logoEdit.alert.remove.primaryAction',
        [AlertType.Exit]: 'employer.logoEdit.alert.exit.primaryAction',
        [AlertType.Back]: 'employer.logoEdit.alert.exit.primaryAction',
    },
};

const LogoEdit: TranslatedComponent = ({ trls }) => {
    const employerId = useSelector((state) => state.employerInfo.id || 0);
    const hasConstructorService = useSelector((state) => state.employerConstructor.hasService);
    const employerLogoEditSettings = useSelectorNonNullable((state) => state.employerLogoEditSettings);
    const logoUrl = useSelector((state) => state.employerLogo.logoUrl);
    const logoStatus = useSelector((state) => state.employerLogo.logoStatus);
    const isRoundLogoUsed = useSelector((state) => state.employerLogo.isRoundLogoUsed);

    const [image, setImage] = useState('');

    const loading = logoStatus === Status.Fetching;
    const photoInputRef = useRef<HTMLInputElement>(null);
    const canvasRef = useRef<HTMLCanvasElement>(null);

    const {
        visibleModalType,
        handleOpenDialog,
        handleSetVisibleModalType,
        handleCloseModal,
        handleBack,
        shouldShowBack,
        shouldShowAlertType,
        alertModalType,
        handleCloseAlert,
        handleShowAlert,
        shouldShowCommunicationModal,
        handleCloseCommunicationModal,
    } = useModalManagement(!!logoUrl, !!isRoundLogoUsed, setImage);
    const { handleSetDimensions, imageCropSettings } = useImageCropSettings();
    const { drawImageWithBackground, getCroppedImage } = useImageCropProcess(
        canvasRef.current,
        setImage,
        handleSetDimensions
    );

    const handleUpload = () => {
        photoInputRef.current?.click();
    };

    const dispatch = useDispatch();
    const { addNotification } = useNotification();

    useEffect(() => {
        if (image) {
            handleSetVisibleModalType(ModalType.ImageCropModal);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [image]);

    const getAnalyticsParams = useCallback(
        (isDragNDrop: boolean, file: File): AnalyticsParams => ({
            actionType: logoUrl ? 'create' : 'edit',
            employerId,
            employerPageConstructorIsActive: hasConstructorService,
            loadType: isDragNDrop ? 'drag-n-drop' : 'button',
            filename: file.name,
            fileSizeKb: file.size / 1024,
            fileHeight: 0,
            fileWidth: 0,
            fileType: file.type,
        }),
        [logoUrl, employerId, hasConstructorService]
    );

    const onLoad = useCallback(
        (accepted: File[], isDragNDrop = true) => {
            if (loading) {
                return;
            }
            const imageFile = accepted[0];

            const analyticsParams = getAnalyticsParams(isDragNDrop, imageFile);

            if (imageFile.size > employerLogoEditSettings.maxSizeBytes) {
                addNotification(Snackbar, employerLogoFileTooLarge);
                employerUploadLogoFormSubmit({ ...analyticsParams, errors: 'EMPLOYER_LOGO_FILE_TOO_LARGE' });
                return;
            }

            if (!employerLogoEditSettings.allowedMimeTypes.includes(imageFile.type)) {
                addNotification(Snackbar, employerLogoUnsupportedFileFormat);
                employerUploadLogoFormSubmit({
                    ...analyticsParams,
                    errors: 'EMPLOYER_LOGO_UNSUPPORTED_FILE_FORMAT',
                });
                return;
            }

            const reader = new FileReader();

            reader.onload = (loadEvent) => {
                const img = new Image();
                img.src = (loadEvent?.target?.result as string) || '';

                img.onload = () => {
                    if (
                        img.width < employerLogoEditSettings.minWidth ||
                        img.height < employerLogoEditSettings.minHeight
                    ) {
                        addNotification(
                            Snackbar,
                            employerLogoFileSizeTooSmall({
                                logoMinWidth: employerLogoEditSettings.minWidth,
                                logoMinHeight: employerLogoEditSettings.minHeight,
                            })
                        );
                        return;
                    }

                    drawImageWithBackground(img);
                };
            };

            reader.readAsDataURL(imageFile);
        },
        [
            loading,
            employerLogoEditSettings.maxSizeBytes,
            employerLogoEditSettings.allowedMimeTypes,
            employerLogoEditSettings.minWidth,
            employerLogoEditSettings.minHeight,
            addNotification,
            drawImageWithBackground,
            getAnalyticsParams,
        ]
    );

    const renderInput = useCallback(
        (getInputProps: GetInputPropsFn) => (
            <input
                {...getInputProps()}
                ref={photoInputRef}
                type="file"
                className="g-hidden"
                accept={employerLogoEditSettings.allowedMimeTypes.join()}
                onChange={(event) => {
                    const imageFile = event.target.files?.[0];
                    event.target.value = '';
                    if (imageFile) {
                        onLoad([imageFile], false);
                    }
                }}
                data-qa="sidebar-upload-logo"
            />
        ),
        [employerLogoEditSettings.allowedMimeTypes, onLoad]
    );

    const renderImageDropZone: DropzoneRenderFunction = useCallback(
        ({ getRootProps, getInputProps }) => {
            return (
                <ElementShownAnchor fn={sidebarElementShown} elementName="change_logo">
                    <div
                        {...getRootProps()}
                        onClick={() => {
                            sidebarButtonClick({ buttonName: 'change_logo' });
                            handleOpenDialog();
                        }}
                        data-qa="change-logo-avatar-mask"
                    >
                        <Layer layer={LayerName.Alpha}>
                            <div className={styles.avatarMask}>
                                <CameraOutlinedSize24 initial="contrast" />
                            </div>
                        </Layer>
                        <div className={styles.logoEditInput}>{renderInput(getInputProps)}</div>
                    </div>
                </ElementShownAnchor>
            );
        },
        [handleOpenDialog, renderInput]
    );

    const handleSaveCropImage = async (params: CropResult) => {
        const file = await getCroppedImage(image, params.absoluteSizes);
        const analyticsParams = getAnalyticsParams(false, file);

        dispatch(uploadLogo({ file, analyticsParams }, addNotification, handleCloseModal)).catch(console.error);
    };

    const primaryAlertAction = () => {
        if (alertModalType === AlertType.Exit) {
            handleCloseModal();
        }

        if (alertModalType === AlertType.Back) {
            handleBack();
        }

        if (alertModalType === AlertType.Remove) {
            dispatch(deleteLogo(addNotification)).catch(console.error);
            handleCloseAlert();
            handleCloseModal();
        }
    };

    return (
        <>
            <Dropzone multiple={false} disableClick={!!logoUrl} onDrop={(accepted) => onLoad(accepted, true)}>
                {renderImageDropZone}
            </Dropzone>
            <ImageViewModal
                onModalClose={handleCloseModal}
                onUpload={handleUpload}
                onOpenAdvice={() => handleSetVisibleModalType(ModalType.Advice)}
                onRemoveLogo={() => handleShowAlert(AlertType.Remove)}
                visible={visibleModalType === ModalType.ImageView}
                logoUrl={logoUrl}
            />
            <ImageCropAdviceModal
                onModalClose={handleCloseModal}
                onUpload={handleUpload}
                onBack={handleBack}
                shouldShowBack={shouldShowBack}
                onRemoveLogo={() => handleShowAlert(AlertType.Remove)}
                visible={visibleModalType === ModalType.Advice}
                canRemoveLogo={!!logoUrl && !isRoundLogoUsed}
            />
            <ImageCropModal
                onBack={() => handleShowAlert(AlertType.Back)}
                shouldShowBack={shouldShowBack}
                visible={visibleModalType === ModalType.ImageCropModal}
                onModalClose={() => handleShowAlert(AlertType.Exit)}
                imageCropSettings={{ ...imageCropSettings, src: image }}
                onSave={handleSaveCropImage}
                isLoading={loading}
            />
            {!!shouldShowAlertType && (
                <ImageAlertModal
                    onModalClose={handleCloseAlert}
                    visible
                    primaryAction={primaryAlertAction}
                    title={trls[TrlKeys.title[alertModalType!]]}
                    primaryActionTitle={trls[TrlKeys.primaryAction[alertModalType!]]}
                    cancel={trls[TrlKeys.cancel[alertModalType!]]}
                />
            )}
            <ImageCropCommunicationModal
                onModalClose={handleCloseCommunicationModal}
                visible={shouldShowCommunicationModal}
            />
            <canvas ref={canvasRef} style={{ display: 'none' }} />
        </>
    );
};

export default translation(LogoEdit);
