import { createContext, useContext } from 'react';

export enum EmployerPageTab {
    Description = 'DESCRIPTION',
    Vacancies = 'VACANCIES',
}

export const isEmployerPageTab = (tab: string): tab is EmployerPageTab => Object.values(EmployerPageTab).includes(tab);

export interface PageTabsContextValue {
    currentTab: EmployerPageTab;
    setCurrentTab: (newTab: string) => void;
    showContentForTab: (tab: EmployerPageTab) => boolean;
    isMobileView: boolean;
}

const PageTabsContext = createContext<PageTabsContextValue | null>(null);

export const usePageTabsContext = (): PageTabsContextValue => {
    const value = useContext(PageTabsContext);
    if (!value) {
        throw new Error('PageTabs context requested outside of provider');
    }
    return value;
};

export default PageTabsContext;
